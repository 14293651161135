import React from 'react';

const EditDetails = ({ userDetails, setUserDetails, saveUserDetails, setIsEditing }) => {
  return (
    <div className="container mt-5">
      <h1>Edit User Details</h1>
      <form onSubmit={(e) => { e.preventDefault(); saveUserDetails(); }}>
        <div className="mb-3">
          <label className="form-label">Age Range</label>
          <select
            className="form-control"
            value={userDetails.ageRange}
            onChange={(e) => setUserDetails({ ...userDetails, ageRange: e.target.value })}
          >
            <option value="">Select Age Range</option>
            <option value="18-25">18-25</option>
            <option value="26-35">26-35</option>
            <option value="36-45">36-45</option>
            <option value="46-55">46-55</option>
            <option value="56+">56+</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Account Count</label>
          <select
            className="form-control"
            value={userDetails.accountCount}
            onChange={(e) => setUserDetails({ ...userDetails, accountCount: e.target.value })}
          >
            <option value="">Please select account type</option>
            <option value="free">Free Account only</option>
            <option value="1">1 Account</option>
            <option value="2">2 Accounts</option>
            <option value="3">3 Accounts</option>
            <option value="4+">More than 3</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Country</label>
          <select
            className="form-control"
            value={userDetails.country}
            onChange={(e) => setUserDetails({ ...userDetails, country: e.target.value })}
          >
            <option value="">Select Country</option>
            {[
              "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda",
              "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain",
              "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia",
              "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso",
              "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic",
              "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
              "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic",
              "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor (Timor-Leste)",
              "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini",
              "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
              "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras",
              "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
              "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "North Korea", "South Korea",
              "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya",
              "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives",
              "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia, Federated States of",
              "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia",
              "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia",
              "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
              "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia",
              "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal",
              "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
              "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
              "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey",
              "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States",
              "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
            ].map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Industry</label>
          <select
            className="form-control"
            value={userDetails.industry}
            onChange={(e) => setUserDetails({ ...userDetails, industry: e.target.value })}
          >
            <option value="">Select Industry</option>
            {[
              "Agriculture", "Arts & Entertainment", "Construction", "Education", "Finance",
              "Healthcare - Medical Devices", "Healthcare - Pharmaceuticals", "Healthcare - Providers",
              "Hospitality", "Information Technology - Software", "Information Technology - Hardware",
              "Manufacturing - Automotive", "Manufacturing - Consumer Goods", "Retail - E-commerce",
              "Retail - Brick and Mortar", "Transportation - Logistics", "Transportation - Public Transit",
              "Other"
            ].map((industry, index) => (
              <option key={index} value={industry}>
                {industry}
              </option>
            ))}
          </select>
        </div>
        {userDetails.industry === 'Other' && (
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              value={userDetails.otherIndustry}
              onChange={(e) => setUserDetails({ ...userDetails, otherIndustry: e.target.value })}
              placeholder="Please specify your industry"
            />
          </div>
        )}
        <div className="mb-3">
          <label className="form-label">Phone Number</label>
          <input
            type="text"
            className="form-control"
            value={userDetails.phoneNumber}
            onChange={(e) => setUserDetails({ ...userDetails, phoneNumber: e.target.value })}
          />
        </div>
        <button type="submit" className="btn btn-primary">Save</button>
        <button type="button" className="btn btn-secondary" onClick={() => setIsEditing(false)}>Cancel</button>
      </form>
    </div>
  );
};

export default EditDetails;
