import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';

const VoiceToText = ({ isListening, setIsListening, countdownText, setCountdownText }) => {
  const handleToggle = () => {
    if (!isListening) {
      setIsListening(true);
      let countdown = 3;
      setCountdownText(countdown);
      let interval = setInterval(() => {
        countdown -= 1;
        if (countdown === 0) {
          clearInterval(interval);
          setCountdownText('You can now start to talk!');
        } else {
          setCountdownText(countdown);
        }
      }, 1000);
    } else {
      setIsListening(false);
      setCountdownText('Stopped, click to start again.');
    }
  };

  return (
    <div>
      <button className="btn btn-dark" onClick={handleToggle}>
        <FontAwesomeIcon icon={faMicrophone} /> {isListening ? 'Stop Listening' : 'Start Listening'}
      </button> {countdownText}
    </div>
  );
};

export default VoiceToText;
