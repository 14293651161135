import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReactCountryFlag from "react-country-flag";

const languages = [
  { name: 'English', code: 'en', flagCode: 'GB', key: 'en-GB' },
  { name: 'Spanish', code: 'es', flagCode: 'ES', key: 'es-ES' },
  { name: 'French', code: 'fr', flagCode: 'FR', key: 'fr-FR' },
  { name: 'Italian', code: 'it', flagCode: 'IT', key: 'it-IT' },
  { name: 'Latin Spanish', code: 'es', flagCode: 'MX', key: 'es-MX' },
  { name: 'Greek', code: 'el', flagCode: 'GR', key: 'el-GR' },
  { name: 'German', code: 'de', flagCode: 'DE', key: 'de-DE' },
  { name: 'Punjabi', code: 'pa', flagCode: 'IN', key: 'pa-IN' },
  { name: 'Mandarin Chinese', code: 'zh', flagCode: 'CN', key: 'zh-CN' },
  { name: 'Cantonese Chinese', code: 'zh', flagCode: 'HK', key: 'zh-HK' },
  { name: 'Japanese', code: 'ja', flagCode: 'JP', key: 'ja-JP' },
  { name: 'Hindi', code: 'hi', flagCode: 'IN', key: 'hi-IN' },
  { name: 'Russian', code: 'ru', flagCode: 'RU', key: 'ru-RU' },
  { name: 'Portuguese', code: 'pt', flagCode: 'PT', key: 'pt-PT' },
  { name: 'Arabic', code: 'ar', flagCode: 'SA', key: 'ar-SA' },
  { name: 'Hebrew', code: 'he', flagCode: 'IL', key: 'he-IL' },
  { name: 'Australian Slang', code: 'en', flagCode: 'AU', key: 'en-AU', context: 'real Aussie lingo, dont hold back with the slang words, make it sound like a drunk Australian slob' },
  { name: 'Cockney British', code: 'en', flagCode: 'GB', key: 'en-GB-2', context: 'real Cockney British lingo, dont hold back with the slang words and lack of teeth' },
  { name: 'American Southern', code: 'en', flagCode: 'US', key: 'en-US', context: 'real American southern bell lingo with slang words' },
  { name: 'millennial', code: 'en', flagCode: 'UN', key: 'en-US-1', context: 'real millennial slang words & lingo, dont hold back on making it sound really millennial sounding' },
  { name: 'Generation Z', code: 'en', flagCode: 'UN', key: 'en-US-2', context: 'real Generation Z slang words & lingo, dont hold back on making it sound really Generation Z sounding make it emo' },
  { name: 'boomer', code: 'en', flagCode: 'UN', key: 'en-US-3', context: 'real boomer talk & slang words & lingo, dont hold back on making it sound really boomer sounding' },

];

const LanguageAssistant = ({ setPopupMessageWithTimeout, mailContent, setMailContent }) => {
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [loading, setLoading] = useState(false);

  const translateText = async (text, language, context) => {
    setLoading(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/translate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: text,
        language: language,
        context: context
      })
    });

    if (!response.ok) {
      setLoading(false);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setLoading(false);
    console.log(data); // Log the response data for debugging
    return data.text ? data.text.trim() : "No translated text found.";
  };

  const handleLanguageChange = async (language) => {
    setSelectedLanguage(language.name);
    setShowLanguageModal(false);

    const messageContent = document.getElementById('message').innerText;
    if (!messageContent.trim()) {
      setPopupMessageWithTimeout("Add some text to translate!");
      return;
    }

    try {
      const translated = await translateText(messageContent, language.code, language.context);
      setMailContent(prev => ({
        ...prev,
        message: translated
      }));
      document.getElementById('message').innerText = translated;
    } catch (error) {
      console.error("Error translating text:", error);
    }
  };

  return (
    <>
      <button className="btn btn-outline-primary btn-sm get-in-touch" type="button" onClick={() => setShowLanguageModal(true)}>language Translations </button>

      {/* Modal for Language Selection */}
      <div className={`modal fade ${showLanguageModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showLanguageModal ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Language</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowLanguageModal(false)}></button>
            </div>
            <div className="modal-body">
              <div className="list-group">
                {languages.map(language => (
                  <button type="button" className="list-group-item list-group-item-action" key={language.key} onClick={() => handleLanguageChange(language)}>
                    <ReactCountryFlag countryCode={language.flagCode} svg style={{ marginRight: '10px', width: '2em', height: '2em' }} />
                    {language.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <div className="spinner-overlay">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      )}
    </>
  );
};

export default LanguageAssistant;
