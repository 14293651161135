import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc, setDoc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { sendPasswordResetEmail, deleteUser } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, storage } from "../firebase-config"; // Add storage import

const Settings = ({ triggerPopupMessage, setActiveTab, setIsEditing }) => {
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userDetails, setUserDetails] = useState({
    ageRange: '',
    accountCount: '',
    country: '',
    industry: '',
    phoneNumber: ''
  });
  const [resumeUrl, setResumeUrl] = useState(''); // Store the uploaded resume URL
  const [uploading, setUploading] = useState(false);
  const [resumeFile, setResumeFile] = useState(null);
  const [showResumePopup, setShowResumePopup] = useState(false); // Control the visibility of the resume popup
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserEmail(user.email);
        setUserName(user.displayName || "User");
        fetchUserDetails();
        fetchResumeUrl(user.uid); // Fetch the resume URL if already uploaded
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchUserDetails = async () => {
    if (!auth.currentUser) return;

    try {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        setUserDetails({
          ageRange: userDoc.data().ageRange || '--',
          accountCount: userDoc.data().accountCount || '--',
          country: userDoc.data().country || '--',
          industry: userDoc.data().industry || '--',
          phoneNumber: userDoc.data().phoneNumber || '--'
        });
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchResumeUrl = async (userId) => {
    try {
      const storageRef = ref(storage, `resumes/${userId}/resume.pdf`);
      const url = await getDownloadURL(storageRef);
      setResumeUrl(url);
    } catch (error) {
      console.error("Error fetching resume URL:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setResumeFile(file);
    } else {
      alert("Please upload a PDF file.");
    }
  };

  const handleResumeUpload = async () => {
    if (!resumeFile) {
      triggerPopupMessage("Please select a resume file first.");
      return;
    }

    if (!auth.currentUser) {
      triggerPopupMessage("You must be logged in to upload a resume.");
      return;
    }

    const storageRef = ref(storage, `resumes/${auth.currentUser.uid}/${resumeFile.name}`);
    setUploading(true);

    try {
      await uploadBytes(storageRef, resumeFile);
      const url = await getDownloadURL(storageRef);
      setResumeUrl(url); // Store the uploaded resume URL
      triggerPopupMessage("Resume uploaded successfully.");
    } catch (error) {
      console.error("Error uploading resume:", error);
      triggerPopupMessage("Failed to upload resume. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const handleResumeDelete = async () => {
    if (!auth.currentUser) return;

    const storageRef = ref(storage, `resumes/${auth.currentUser.uid}/resume.pdf`);

    try {
      await deleteObject(storageRef);
      setResumeUrl(''); // Clear the resume URL from state
      triggerPopupMessage("Resume deleted successfully.");
    } catch (error) {
      console.error("Error deleting resume:", error);
      triggerPopupMessage("Failed to delete resume. Please try again.");
    }
  };

  const handlePasswordReset = async () => {
    await sendPasswordResetEmail(auth, auth.currentUser.email);
    triggerPopupMessage("Password reset email sent!");
  };

  const handleRemoveAccount = async () => {
    const user = auth.currentUser;
    if (!user) {
      triggerPopupMessage("No authenticated user. Please log in.");
      return;
    }

    try {
      const userRef = doc(db, "users", user.uid);
      await deleteDoc(userRef);
      await deleteUser(user);
      triggerPopupMessage("Account removed successfully!");
      navigate("/login");
    } catch (error) {
      console.error("Error removing account:", error);
      triggerPopupMessage(`Failed to remove account: ${error.message}`);
    }
  };

  const capitalize = (s) => {
    return typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : '';
  };

  return (
    <div className="container mt-5">
      <div className="card shadow-lg">
        <div className="card-header  text-white">
          <h2 className="mb-0">Account Settings</h2>
        </div>
        <div className="card-body">\
        <div className="mb-4">

           {/* Resume Section */}
  
          {/* Resume Upload/View Section */}
          <div className="mb-4">
            <h4 className="text-secondary">Resume</h4>
            {resumeUrl ? (
              <div className="d-flex align-items-center">
                <button className="btn btn-secondary me-2" onClick={() => setShowResumePopup(true)}>
                  View Resume
                </button>
                <button className="btn btn-danger" onClick={handleResumeDelete}>
                  Delete Resume
                </button>
                <div className="alert alert-info ms-3 mb-0">
                  Resume available. <a href={resumeUrl} target="_blank" rel="noopener noreferrer">Download your resume</a>
                </div>
              </div>
            ) : (
              <p className="text-muted">No resume uploaded yet.</p>
            )}
          </div>

               {/* Profile Section */}
            <h4 className="text-secondary">Your Profile</h4>
            <p><strong>Name:</strong> {capitalize(userName)}</p>
            <p><strong>Email:</strong> {userEmail}</p>
            <p><strong>Age Range:</strong> {userDetails.ageRange}</p>
            <p><strong>Account Count:</strong> {userDetails.accountCount}</p>
            <p><strong>Country:</strong> {userDetails.country}</p>
            <p><strong>Industry:</strong> {userDetails.industry}</p>
            <p><strong>Phone Number:</strong> {userDetails.phoneNumber}</p>
          </div>

          {/* Profile Customizations */}
          <div className="mb-4">
            <h4 className="text-secondary">Customize Profile</h4>
            <button className="btn btn-primary me-2" onClick={() => setActiveTab('edit-signature')}>
              Edit Signature
            </button>
            <button className="btn btn-primary" onClick={() => setIsEditing(true)}>
              Edit Details
            </button>
          </div>

          {/* Account Actions */}
          <div className="mb-4">
            <h4 className="text-secondary">Manage Account</h4>
            <button className="btn btn-warning me-2" onClick={handlePasswordReset}>
              Change Password
            </button>
            <button className="btn btn-danger" onClick={handleRemoveAccount}>
              Remove Account
            </button>
          </div>
        </div>
      </div>
  
      {/* Modal for Viewing Resume */}
      {showResumePopup && resumeUrl && (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Your Resume</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowResumePopup(false)}></button>
              </div>
              <div className="modal-body">
                <iframe src={resumeUrl} width="100%" height="600px" title="Resume"></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  
};

export default Settings;
