import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const SavedMails = ({ savedMails, loadMailForEdit, deleteMail }) => {
  return (
    <div className="container mt-5">
      <h1>Saved U-Mails</h1>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Subject</th>
            <th scope="col">Message</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {savedMails.map((mail, index) => (
            <tr key={mail.id || index}>
              <td>{mail.subject}</td>
              <td>{mail.message}</td>
              <td>
                <button className="btn btn-info" onClick={() => loadMailForEdit(mail)}>
                  <FontAwesomeIcon icon={faPen} /> Edit
                </button>
                <button className="btn btn-danger" onClick={(e) => {
                  e.stopPropagation();
                  deleteMail(mail.id);
                }}>
                  <FontAwesomeIcon icon={faTrash} /> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SavedMails;
