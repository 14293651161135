import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const SynonymAssistant = ({ setPopupMessageWithTimeout }) => {
  const [showSynonymModal, setShowSynonymModal] = useState(false);
  const [selectedWord, setSelectedWord] = useState('');
  const [synonyms, setSynonyms] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHighlightedText = () => {
    let selectedText = '';
    if (window.getSelection) {
      selectedText = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== 'Control') {
      selectedText = document.selection.createRange().text;
    }
    return selectedText;
  };

  const fetchSynonyms = async (creative = false) => {
    const selectedText = getHighlightedText();
    if (!selectedText.trim()) {
      setPopupMessageWithTimeout("Please highlight a word to find synonyms.");
      return;
    }
    setSelectedWord(selectedText);
    setShowSynonymModal(true);
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/synonyms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          word: selectedText,
          creative
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSynonyms(data.synonyms || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching synonyms:", error);
      setLoading(false);
    }
  };

  const replaceSelectedText = (replacementText) => {
    setShowSynonymModal(false);
    const range = window.getSelection().getRangeAt(0);
    range.deleteContents();
    range.insertNode(document.createTextNode(replacementText));
  };

  return (
    <>
      <button className="btn btn-primary btn-sm get-in-touch" type="button" onClick={() => fetchSynonyms(false)}>Synonym assistant</button>

      {/* Modal for Synonyms */}
      <div className={`modal fade ${showSynonymModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showSynonymModal ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Synonyms for "{selectedWord}"</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowSynonymModal(false)}></button>
            </div>
            <div className="modal-body">
              {loading ? (
                <div className="spinner-overlay">
                  <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                </div>
              ) : synonyms.length > 0 ? (
                <div className="d-flex flex-wrap">
                  {synonyms.map((synonym, index) => (
                    <button
                      key={index}
                      className="btn btn-outline-secondary btn-sm m-1"
                      onClick={() => {
                        replaceSelectedText(synonym);
                        setSynonyms([synonym]); // Update the synonyms to only show the clicked one
                      }}
                    >
                      {synonym}
                    </button>
                  ))}
                </div>
              ) : (
                <p>No synonyms found.</p>
              )}
              <button
                className="btn btn-secondary btn-sm mt-2"
                onClick={() => fetchSynonyms(true)}
              >
                Rejuvenate Synonyms
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SynonymAssistant;
