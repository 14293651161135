import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const styles = [
  { name: 'Descriptive' },
  { name: 'Analytical' },
  { name: 'Poetic' },
  { name: 'Innovative' },
  { name: 'Inclusive' },
  { name: 'Creative' },
  { name: 'Empathetic' },
  { name: 'Energetic' },
  { name: 'Narrative' },
  { name: 'Engaging' },
  { name: 'Inspirational' },
  { name: 'Optimistic' },
  { name: 'Visionary' },
  { name: 'Motivational' },
  { name: 'Persuasive' },
  { name: 'Witty' },
  { name: 'Insightful' },
];

const WritingStyleAssistant = ({ setPopupMessageWithTimeout, setShowWritingStyleModal }) => {
  const [loading, setLoading] = useState(false);

  const enhanceTextWithStyle = async (text, style) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/style-enhance`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text, style }),
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setLoading(false);
      return data.enhancedText ? data.enhancedText.trim() : "No enhanced text found.";
    } catch (error) {
      console.error("Error enhancing text:", error);
      setPopupMessageWithTimeout("Error enhancing text.");
      setLoading(false);
    }
  };

  const handleStyleChange = async (style) => {
    setShowWritingStyleModal(false);

    const messageContent = document.getElementById('message').innerText;
    if (!messageContent.trim()) {
      setPopupMessageWithTimeout("Add some text to enhance!");
      return;
    }

    try {
      const enhanced = await enhanceTextWithStyle(messageContent, style.name);
      document.getElementById('message').innerText = enhanced;
    } catch (error) {
      console.error("Error applying writing style:", error);
    }
  };

  return (
    <>
      {/* Modal for Writing Style Selection */}
      <div className={`modal fade show`} tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Writing Style</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowWritingStyleModal(false)}></button>
            </div>
            <div className="modal-body">
              <div className="list-group">
                {styles.map((style, index) => (
                  <button
                    type="button"
                    className="list-group-item list-group-item-action"
                    key={index}
                    onClick={() => handleStyleChange(style)}
                  >
                    {style.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <div className="spinner-overlay">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      )}
    </>
  );
};

export default WritingStyleAssistant;
