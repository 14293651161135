import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase-config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faEnvelope,
  faPlus,
  faArrowDown,
  faQuestionCircle,
  faCog,
  faBriefcase,
  faReply,
  faForward,
  faTrash,
  faStar,
  faExclamation,
  faBars,
  faGrip,
  faEllipsisVertical, faPrint,faBan, faExclamationTriangle, faFish,faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import NewMail from "../scripts/newUmAIl";
import FAQ from "./loggedIn/FAQ";
import EditDetails from "./loggedIn/EditDetails";
import Settings from "./Settings";
import Inbox from "./loggedIn/Inbox";
import SavedMails from "./loggedIn/SavedMails";
import SentMails from "./loggedIn/SentMails";
import VoiceToText from "./VoiceToText";
import JobHelp from "./loggedIn/JobHelp";
import axiosInstance from "../axiosInstance";
import axios from "axios";
import Labels from "./loggedIn/Labels"; 

const LoggedIn = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [savedMails, setSavedMails] = useState([]);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [mailContent, setMailContent] = useState({
    id: null,
    from: "",
    to: "",
    subject: "",
    message: "",
  });
  const [activeTab, setActiveTab] = useState("received-mails");
  const [popupMessage, setPopupMessage] = useState("");
  const [showMailPopup, setShowMailPopup] = useState(false);
  const [selectedMail, setSelectedMail] = useState(null);
  const [signature, setSignature] = useState("");
  const [useSignature, setUseSignature] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem("accessToken") || null
  );
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("primary");
  const [sentEmails, setSentEmails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const emailsPerPage = 20;
  const [isListening, setIsListening] = useState(false);
  const [countdownText, setCountdownText] = useState("Ready");
  const [inboxEmails, setInboxEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]); // Track selected emails
const [allSelected, setAllSelected] = useState(false); // Track if all are selected
const [starredEmails, setStarredEmails] = useState([]);
const [importantEmails, setImportantEmails] = useState([]);
const [loading, setLoading] = useState(false);  // Loading state
const [hoveredRow, setHoveredRow] = useState(null); // Track which row is hovered


  const triggerPopupMessage = (message) => {
    setPopupMessage(message);
    setTimeout(() => setPopupMessage(""), 4000);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) navigate("/login");
      else {
        setUserEmail(user.email);
        fetchSavedMails();
      }
    });

    const token = sessionStorage.getItem("accessToken");
    if (token) {
      setAccessToken(token);
      fetchInboxEmails(token);
      fetchSentEmails(token);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get("code");

    if (authorizationCode) {
      exchangeAuthorizationCode(authorizationCode);
    } else if (sessionStorage.getItem("accessToken")) {
      const storedTime = sessionStorage.getItem("tokenTime");
      if (new Date().getTime() - storedTime > 3600000) {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("tokenTime");
        setAccessToken(null);
      }
    }

    return () => unsubscribe();
  }, [navigate]);
  

  const exchangeAuthorizationCode = async (code) => {
    try {
      const response = await axiosInstance.post("/api/exchange-code", { code });
      const { access_token } = response.data;

      sessionStorage.setItem("accessToken", access_token);
      sessionStorage.setItem("tokenTime", new Date().getTime());
      setAccessToken(access_token);

      fetchInboxEmails(access_token);
      fetchSentEmails(access_token);
    } catch (error) {
      console.error("Error exchanging authorization code:", error);
      setPopupMessage("Error exchanging authorization code.");
    }
  };

  // const fetchInboxEmails = async (token) => {
  //   try {
  //     const response = await axios.get(
  //       "https://www.googleapis.com/gmail/v1/users/me/messages",
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //         params: {
  //           maxResults: 75,
  //           q: "in:inbox",
  //         },
  //       }
  //     );

  //     const messages = response.data.messages || [];
  //     if (!Array.isArray(messages)) {
  //       console.error("The response did not return a valid array of messages.");
  //       return;
  //     }

  //     const detailedMessages = await Promise.all(
  //       messages.map(async (message) => {
  //         const messageDetails = await axios.get(
  //           `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
  //           { headers: { Authorization: `Bearer ${token}` } }
  //         );
  //         return messageDetails.data;
  //       })
  //     );

  //     setInboxEmails(detailedMessages);
  //     setFilteredEmails(detailedMessages);
  //   } catch (error) {
  //     console.error("Failed to fetch inbox emails:", error);
  //   }
  // };

  const fetchInboxEmails = async (token) => {
    setLoading(true);  // Start the spinner
    try {
      const response = await axios.get(
        "https://www.googleapis.com/gmail/v1/users/me/messages",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { maxResults: 75, q: "in:inbox" },
        }
      );
  
      const messages = response.data.messages || [];
      const detailedMessages = await Promise.all(
        messages.map(async (message) => {
          const messageDetails = await axios.get(
            `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          return messageDetails.data;
        })
      );
  
      // Sort emails into categories: Primary, Social, Promotions
      const primaryEmails = detailedMessages.filter(
        (email) =>
          email.labelIds.includes("CATEGORY_PRIMARY") ||
          (!email.labelIds.includes("CATEGORY_PROMOTIONS") &&
            !email.labelIds.includes("CATEGORY_SOCIAL"))
      );
  
      const socialEmails = detailedMessages.filter((email) =>
        email.labelIds.includes("CATEGORY_SOCIAL")
      );
  
      const promotionsEmails = detailedMessages.filter((email) =>
        email.labelIds.includes("CATEGORY_PROMOTIONS")
      );
  
      // Update the state with sorted emails
      setInboxEmails([...primaryEmails, ...socialEmails, ...promotionsEmails]);
      setFilteredEmails([...primaryEmails, ...socialEmails, ...promotionsEmails]);
  
      setLoading(false);  // Stop the spinner
    } catch (error) {
      console.error("Failed to fetch inbox emails:", error);
      setLoading(false);  // Stop the spinner even on error
    }
  };
  

  const fetchSentEmails = async (token) => {
    try {
      const response = await axios.get(
        "https://www.googleapis.com/gmail/v1/users/me/messages",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            q: "in:sent",
            maxResults: 100,
          },
        }
      );
      const messages = response.data.messages || [];
      setSentEmails(messages);
    } catch (error) {
      console.error("Failed to fetch sent emails:", error);
    }
  };

  const fetchSavedMails = async () => {
    if (!auth.currentUser) return;

    try {
      const q = query(
        collection(db, "savedMails"),
        where("userId", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const mails = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSavedMails(querySnapshot.empty ? [] : mails);
    } catch (error) {
      console.error("Error fetching saved mails:", error);
    }
  };

  
const handleMailClick = async (emailId) => {
  if (selectedMail && selectedMail.id === emailId) {
    // Close the opened email if clicked again
    setSelectedMail(null);
  } else {
    const selectedEmail = inboxEmails.find(email => email.id === emailId);

    // Extract attachments (for inline images)
    const attachments = {};
    const attachmentParts = selectedEmail.payload.parts?.filter(
      part => part.filename && part.body && part.body.attachmentId
    );

    // Fetch attachments if any (for inline images)
    if (attachmentParts?.length) {
      await Promise.all(attachmentParts.map(async (part) => {
        const attachmentResponse = await axios.get(
          `https://www.googleapis.com/gmail/v1/users/me/messages/${selectedEmail.id}/attachments/${part.body.attachmentId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        );
        attachments[part.body.attachmentId] = attachmentResponse.data.data;
      }));
    }

    // Prefer the HTML part of the email if it exists, otherwise use plain text
    const bodyPart = selectedEmail.payload.parts?.find(
      (part) => part.mimeType === "text/html" || part.mimeType === "text/plain"
    );

    const bodyData = bodyPart?.body?.data || "";
    let decodedMessage = decodeBase64(bodyData);

    // Replace cid: references with the actual attachment data
    if (bodyPart?.mimeType === "text/html") {
      decodedMessage = decodedMessage.replace(/cid:([^'">]+)/g, (match, cid) => {
        const attachment = attachmentParts?.find(
          part => part.headers?.some(header => header.name === 'Content-ID' && header.value.includes(cid))
        );
        if (attachment && attachments[attachment.body.attachmentId]) {
          const attachmentData = attachments[attachment.body.attachmentId];
          return `data:${attachment.mimeType};base64,${attachmentData}`;
        }
        return match;  // Return the original cid: if no matching attachment is found
      });
    }

    setSelectedMail({
      ...selectedEmail,
      decodedMessage,  // HTML or plain text body with inline images replaced
      isHtml: bodyPart?.mimeType === "text/html",  // Track if it's HTML
    });
  }
};

  
  
  
  
  

  const handleClosePopup = () => {
    setShowMailPopup(false);
    setSelectedMail(null);
  };

  const loadMailForEdit = (mail) => {
    setMailContent({
      id: mail.id,
      from: userEmail,
      to: mail.to,
      subject: mail.subject,
      message: mail.message,
    });
    setActiveTab("new-u-mail");
  };

  const handleGoogleSignIn = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://mail.google.com/&response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=http://localhost:3000/logged-in&access_type=offline&prompt=consent`;
    window.location.href = authUrl;
  };

  const handleReply = (email) => {
    const from = email.payload.headers.find((header) => header.name === "From")?.value || "";
    const subject = `Re: ${email.payload.headers.find((header) => header.name === "Subject")?.value || ""}`;
    const messagePart = email.payload.parts?.find((part) => part.mimeType === "text/plain");
    const message = messagePart ? decodeBase64(messagePart.body.data) : email.snippet;
  
    setMailContent({
      from: userEmail,  // Logged-in user's email
      to: from,  // The sender of the email
      subject: subject,
      message: `\n\n--- Original Message ---\n${message}`,
    });
    setActiveTab("new-u-mail");
  };
  

  const handleForward = (email) => {
    const subject = `Fwd: ${email.payload.headers.find((header) => header.name === "Subject")?.value || ""}`;
    const messagePart = email.payload.parts?.find((part) => part.mimeType === "text/plain");
    const message = messagePart ? decodeBase64(messagePart.body.data) : email.snippet;
  
    setMailContent({
      from: userEmail,  // Logged-in user's email
      to: "",  // Empty because the user will choose whom to forward it to
      subject: subject,
      message: `\n\n--- Forwarded Message ---\n${message}`,
    });
    setActiveTab("new-u-mail");
  };

  const handleDelete = async (emailId) => {
    try {
      // Send the delete request to Gmail API
      await axios.delete(`https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
  
      // Remove the deleted email from inboxEmails and filteredEmails
      const updatedInboxEmails = inboxEmails.filter(email => email.id !== emailId);
      setInboxEmails(updatedInboxEmails);
      setFilteredEmails(updatedInboxEmails);
  
      // If the selected email is the one being deleted, clear the selection
      if (selectedMail && selectedMail.id === emailId) {
        setSelectedMail(null);
      }
  
      // Show success message
      setPopupMessage("Email deleted successfully.");
      setTimeout(() => setPopupMessage(""), 3000);
    } catch (error) {
      console.error("Failed to delete email:", error);
      setPopupMessage("Failed to delete email.");
      setTimeout(() => setPopupMessage(""), 3000);
    }
  };
  
  

  const indexOfLastEmail = currentPage * emailsPerPage;
  const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;
  const currentEmails = filteredEmails.slice(indexOfFirstEmail, indexOfLastEmail);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredEmails.length / emailsPerPage);

  const paginateNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const paginatePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleFilterChange = (filter) => {
    if (!Array.isArray(inboxEmails)) {
      console.error("inboxEmails is not an array");
      return;
    }

    let filtered = [];

    if (filter === "primary") {
      filtered = inboxEmails.filter(
        (email) =>
          email.labelIds.includes("CATEGORY_PRIMARY") ||
          (!email.labelIds.includes("CATEGORY_PROMOTIONS") &&
            !email.labelIds.includes("CATEGORY_SOCIAL"))
      );
    } else if (filter === "promotions") {
      filtered = inboxEmails.filter((email) =>
        email.labelIds.includes("CATEGORY_PROMOTIONS")
      );
    } else if (filter === "social") {
      filtered = inboxEmails.filter((email) =>
        email.labelIds.includes("CATEGORY_SOCIAL")
      );
    } else if (filter === "updates") {
      filtered = inboxEmails.filter((email) =>
        email.labelIds.includes("CATEGORY_UPDATES")
      );
    }

    setFilteredEmails(filtered);
    setSelectedFilter(filter);
    setCurrentPage(1);
  };

  const decodeBase64 = (encodedData) => {
    try {
      // Fix padding and replace characters before decoding
      const fixedEncodedData = encodedData.replace(/-/g, '+').replace(/_/g, '/').padEnd(encodedData.length + (encodedData.length % 4), '=');
      return decodeURIComponent(escape(window.atob(fixedEncodedData)));
    } catch (error) {
      console.error("Error decoding base64:", error);
      return "Error decoding message.";
    }
  };
  
  // Handle Star Click
const handleStarClick = async (e, emailId) => {
  e.stopPropagation(); // Prevent selecting the email row when clicking the star

  try {
    // Logic to toggle star (either add or remove from STARRED label)
    const email = inboxEmails.find((email) => email.id === emailId);
    const isStarred = email.labelIds?.includes("STARRED");

    if (isStarred) {
      await axios.post(`https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`, {
        removeLabelIds: ["STARRED"]
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
    } else {
      await axios.post(`https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`, {
        addLabelIds: ["STARRED"]
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
    }

    // Refresh inbox or update state
    fetchInboxEmails(accessToken);
  } catch (error) {
    console.error("Failed to toggle star:", error);
  }
};

// Handle Important Click
const handleImportantClick = async (e, emailId) => {
  e.stopPropagation(); // Prevent selecting the email row when clicking the important marker

  try {
    // Logic to toggle important label
    const email = inboxEmails.find((email) => email.id === emailId);
    const isImportant = email.labelIds?.includes("IMPORTANT");

    if (isImportant) {
      await axios.post(`https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`, {
        removeLabelIds: ["IMPORTANT"]
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
    } else {
      await axios.post(`https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`, {
        addLabelIds: ["IMPORTANT"]
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
    }

    // Refresh inbox or update state
    fetchInboxEmails(accessToken);
  } catch (error) {
    console.error("Failed to toggle important marker:", error);
  }
};

  // Handle checkbox click for individual emails
const handleCheckboxClick = (e, emailId) => {
  e.stopPropagation(); // Prevent the row from being selected when clicking the checkbox
  if (selectedEmails.includes(emailId)) {
    setSelectedEmails(selectedEmails.filter(id => id !== emailId)); // Remove from selection
  } else {
    setSelectedEmails([...selectedEmails, emailId]); // Add to selection
  }
};

// Handle "Select All" checkbox
const handleSelectAll = () => {
  if (allSelected) {
    setSelectedEmails([]); // Deselect all emails
  } else {
    const allEmailIds = currentEmails.map(email => email.id);
    setSelectedEmails(allEmailIds); // Select all emails
  }
  setAllSelected(!allSelected); // Toggle allSelected state
};

  // Toggle options menu visibility
  const toggleOptionsMenu = () => {
    setShowOptionsMenu(!showOptionsMenu);
  };

  const [labels, setLabels] = useState([]);

const fetchGmailLabels = async (token) => {
  try {
    const response = await axios.get(
      'https://www.googleapis.com/gmail/v1/users/me/labels',
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setLabels(response.data.labels || []);
  } catch (error) {
    console.error("Failed to fetch Gmail labels:", error);
  }
};

// Call this function after the token is available
useEffect(() => {
  if (accessToken) {
    fetchGmailLabels(accessToken);
  }
}, [accessToken]);

const [selectedLabel, setSelectedLabel] = useState("");

  // Handle "Add to Label" functionality (Placeholder)
  const handleAddToLabel = async (emailId) => {
    if (!selectedLabel) {
      alert("Please select a label.");
      return;
    }
  
    try {
      await axios.post(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`,
        { addLabelIds: [selectedLabel] },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      alert("Label added successfully!");
    } catch (error) {
      console.error("Failed to add label:", error);
      alert("Failed to add label.");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow dropping
  };
  
  const handleDrop = async (e, labelId) => {
    e.preventDefault();
    const emailId = e.dataTransfer.getData("emailId");
  
    try {
      await axios.post(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/modify`,
        { addLabelIds: [labelId] },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      alert("Email added to label!");
    } catch (error) {
      console.error("Error adding label:", error);
    }
  };
  
  const handleDragStart = (e, emailId) => {
    e.dataTransfer.setData("emailId", emailId);
  };
  
  const handlePrint = () => {
    window.print();
  };
  
  const handleBlockSender = async () => {
    const senderEmail = selectedMail.payload.headers.find(
      (header) => header.name === "From"
    )?.value;
    
    // Add logic to block the sender here
    alert(`Sender ${senderEmail} blocked.`);
  };
  
  const handleReportSpam = async () => {
    try {
      // Mark the email as SPAM
      await axios.post(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${selectedMail.id}/modify`,
        { addLabelIds: ["SPAM"] },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
  
      // Delete the email after marking it as spam
      await axios.delete(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${selectedMail.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
  
      // Set popup message
      setPopupMessage("Reported as spam and deleted.");
  
      // Clear the popup message after 3 seconds
      setTimeout(() => {
        setPopupMessage("");
      }, 3000);
  
      // Optionally update the inbox by removing the deleted email
      setInboxEmails(inboxEmails.filter(email => email.id !== selectedMail.id));
    } catch (error) {
      console.error("Failed to report spam and delete the email:", error);
      alert("Failed to report spam.");
    }
  };
  

  const handleReportPhishing = async () => {
    try {
      await axios.post(
        `https://www.googleapis.com/gmail/v1/users/me/messages/${selectedMail.id}/modify`,
        { addLabelIds: ["PHISHING"] },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      alert("Reported as phishing.");
    } catch (error) {
      console.error("Failed to report phishing:", error);
    }
  };
  
  const handleRefreshInbox = async () => {
    setLoading(true);  // Start the loader
    try {
      await fetchInboxEmails(accessToken);
      setPopupMessage("Inbox refreshed.");
      setTimeout(() => setPopupMessage(""), 3000);
      setLoading(false);  // Stop the loader
    } catch (error) {
      console.error("Failed to refresh inbox:", error);
      setPopupMessage("Failed to refresh inbox.");
      setTimeout(() => setPopupMessage(""), 3000);
      setLoading(false);  // Stop the loader on error
    }
  };
  

  return (
    <>

{loading && (
  <div className="spinner">
    <FontAwesomeIcon icon="spinner" spin />
  </div>
)}
{loading && (
  <div className="spinner">
    <FontAwesomeIcon icon="spinner" spin />
  </div>
)}

      <div className="container-fluid">
        <div className="row">
          <nav
            id="sidebarMenu"
            className="col-md-3 col-lg-2 d-md-block bg-light sidebar"
          >
            <div className="position-sticky pt-3 sidebar-sticky">
              <ul className="nav flex-column">
                <li
                  className="nav-item btn btn-success"
                  onClick={() => setActiveTab("new-u-mail")}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Compose
                </li>
                <li
                  className="nav-item btn btn-light"
                  onClick={() => setActiveTab("received-mails")}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  Inbox
                </li>
                <li
                  className="nav-item btn btn-light"
                  onClick={() => setActiveTab("sent-mails")}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                  Sent
                </li>
                <li
                  className="nav-item btn btn-light"
                  onClick={() => setActiveTab("saved-u-mails")}
                >
                  <FontAwesomeIcon icon={faArrowDown} />
                  Saved/Drafts
                </li>
                <li
                  className="nav-item btn btn-light"
                  onClick={() => setActiveTab("job-help")}
                >
                  <FontAwesomeIcon icon={faBriefcase} />
                  Job Assistant tool
                </li>
                <li
                  className="nav-item btn btn-light"
                  onClick={() => setActiveTab("help-faqs")}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                  Help/FAQs
                </li>
                <li
                  className="nav-item btn btn-light"
                  onClick={() => setActiveTab("settings")}
                >
                  <FontAwesomeIcon icon={faCog} />
                  Account Settings
                </li>
  
                {/* Labels with Drag and Drop */}
                {labels.map((label) => (
                  <li
                    key={label.id}
                    className="nav-item justify-content-between sidebar-stick nav-item btn btn-light"
                    onDragOver={handleDragOver}  // Allow dragging over label
                    onDrop={(e) => handleDrop(e, label.id)}  // Handle drop action
                  >
                    {label.name}
                  </li>
                ))}
              </ul>
            </div>
          </nav>
  
          <div className="col-md-6 col-lg-8">
            {activeTab === "new-u-mail" ? (
              <div className="container mt-5">
                <h1 className="mb-3">
                  <i className="fas fa-microphone"></i> New U-Mail
                </h1>
                <div className="d-grid gap-2 d-md-block">
                  <NewMail
                    isListening={isListening}
                    setCountdownText={setCountdownText}
                    handleFetchedMails={setSavedMails}
                    setPopupMessage={setPopupMessage}
                    useSignature={useSignature}
                    setUseSignature={setUseSignature}
                    signature={signature}
                    setSignature={setSignature}
                    mailContent={mailContent}
                    setMailContent={setMailContent}
                    accessToken={accessToken}
                    userEmail={userEmail}
                  />
                  <VoiceToText
                    isListening={isListening}
                    setIsListening={setIsListening}
                    countdownText={countdownText}
                    setCountdownText={setCountdownText}
                  />
                </div>
              </div>
            ) : activeTab === "saved-u-mails" ? (
              <SavedMails savedMails={savedMails} loadMailForEdit={loadMailForEdit} />
            ) : activeTab === "sent-mails" ? (
              <SentMails
                accessToken={accessToken}
                sentMails={sentEmails}
                handleMailClick={handleMailClick}
                showMailPopup={showMailPopup}
                selectedMail={selectedMail}
                handleClosePopup={handleClosePopup}
              />
            ) : activeTab === "received-mails" ? (
              <div className="container mt-5">
                <h1>Received U-Mails</h1>
  
                <div className="btn-group mb-3">
                  <button
                    className={`btn ${selectedFilter === "primary" ? "btn-primary" : "btn-light"}`}
                    onClick={() => handleFilterChange("primary")}
                  >
                    Primary
                  </button>
                  <button
                    className={`btn ${selectedFilter === "promotions" ? "btn-primary" : "btn-light"}`}
                    onClick={() => handleFilterChange("promotions")}
                  >
                    Promotions
                  </button>
                  <button
                    className={`btn ${selectedFilter === "social" ? "btn-primary" : "btn-light"}`}
                    onClick={() => handleFilterChange("social")}
                  >
                    Social
                  </button>
                  <button
                    className={`btn ${selectedFilter === "updates" ? "btn-primary" : "btn-light"}`}
                    onClick={() => handleFilterChange("updates")}
                  >
                    Updates
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={() => handleRefreshInbox()}
                    title="Refresh Inbox"
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  >
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </button>

                </div>
  
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            checked={allSelected}
                            onChange={handleSelectAll}
                          />
                          {selectedEmails.length > 0 && (
                            <FontAwesomeIcon
                              icon={faBars}
                              className="ml-2"
                              onClick={toggleOptionsMenu}
                              style={{ cursor: "pointer", marginLeft: "4px" }}
                              title="Options"
                            />
                          )}
                        </div>
                        {showOptionsMenu && (
                          <div className="dropdown-menu show">
                            <button
                              className="dropdown-item"
                              onClick={() => selectedEmails.forEach(handleDelete)}
                            >
                              <FontAwesomeIcon icon={faTrash} /> Delete
                            </button>
                            <button
                              className="dropdown-item"
                              onClick={() =>
                                selectedEmails.forEach((id) =>
                                  handleReply(inboxEmails.find((email) => email.id === id))
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faReply} /> Reply
                            </button>
                            <button
                              className="dropdown-item"
                              onClick={() =>
                                selectedEmails.forEach((id) =>
                                  handleForward(inboxEmails.find((email) => email.id === id))
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faForward} /> Forward
                            </button>
                          </div>
                        )}
                      </th>
                      <th scope="col"></th> {/* Star column */}
                      <th scope="col"></th> {/* Important column */}
                      <th scope="col">From</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Date</th>
                      <th scope="col"> </th>
                    </tr>
                  </thead>
  
                  <tbody>
                    {currentEmails.length > 0 ? (
                      currentEmails.map((email, index) => {
                        const fromHeader = email?.payload?.headers?.find(
                          (header) => header.name === "From"
                        )?.value || "Unknown";
                        const subjectHeader = email?.payload?.headers?.find(
                          (header) => header.name === "Subject"
                        )?.value || "No Subject";
                        const dateHeader = email?.payload?.headers?.find(
                          (header) => header.name === "Date"
                        )?.value || "Unknown Date";
  
                        const emailDate = new Date(dateHeader);
                        const today = new Date();
                        const isToday = emailDate.toDateString() === today.toDateString();
                        const formattedDate = isToday
                          ? emailDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
                          : emailDate.toLocaleDateString();
  
                        const isStarred = email.labelIds?.includes("STARRED");
                        const isImportant = email.labelIds?.includes("IMPORTANT");
                        const isUnread = email.labelIds?.includes("UNREAD");
                        const isChecked = selectedEmails.includes(email.id);
                        const isEmailSelected = selectedMail && selectedMail.id === email.id;
  
                        return (
                          <React.Fragment key={email.id || index}>
                            <tr
                              className={`email-row ${isUnread ? "unread-email" : ""} ${isEmailSelected ? "" : "hover-effect"}`}
                              style={{
                                cursor: "pointer",
                                transition: "background-color 0.2s ease",
                              }}
                              onClick={() => handleMailClick(email.id)}
                              draggable="true" // Add draggable functionality to email row
                              onDragStart={(e) => handleDragStart(e, email.id)} // Start dragging the email
                            >

<td
  style={{ position: 'relative' }}
  onMouseEnter={() => setHoveredRow(email.id)}  // Track when this row is hovered
  onMouseLeave={() => setHoveredRow(null)}      // Reset when hover leaves
>
  <span
    className="drag-icon"
    style={{
      cursor: 'pointer',
      fontSize: '16px',
      display: hoveredRow === email.id ? 'inline-block' : 'none',  // Show icon only for the hovered row
    }}
    title="Select to drag to label"
  >
    <FontAwesomeIcon icon={faGrip} />
  </span>
</td>


                              <td onClick={(e) => e.stopPropagation()}>
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={(e) => handleCheckboxClick(e, email.id)}
                                />
                              </td>
  
                              <td onClick={(e) => { e.stopPropagation(); handleStarClick(e, email.id); }}>
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className={isStarred ? "text-warning" : "text-muted"}
                                  title="Mark as Starred to move this email to the Starred section"
                                />
                              </td>
  
                              <td onClick={(e) => { e.stopPropagation(); handleImportantClick(e, email.id); }}>
                                <FontAwesomeIcon
                                  icon={faExclamation}
                                  className={isImportant ? "text-danger" : "text-muted"}
                                  title="Mark as Important to move this email to the Important section"
                                />
                              </td>
  
                              <td>{fromHeader}</td>
                              <td>{subjectHeader}</td>
                              <td style={{ minWidth: "85px" }}>{formattedDate}</td>
                            </tr>
  
                            {isEmailSelected && (
                              <tr>
                                <td colSpan="6">
                                  <div className="email-body mt-4 p-3" style={{ border: "1px solid #ddd", borderRadius: "8px", backgroundColor: "#f9f9f9", maxWidth: "989px" }}>
                                    <div className="email-header mb-3">
                                      <h5 className="email-subject" style={{ fontSize: "18px", fontWeight: "bold" }}>
                                        {subjectHeader}
                                        <div className="email-actions floatR">
                                        <td onClick={(e) => { e.stopPropagation(); handleStarClick(e, email.id); }}>
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className={isStarred ? "text-warning" : "text-muted"}
                                  title="Mark as Starred to move this email to the Starred section"
                                />
                              </td>
  
                      
                                          <button className="btn btn-light" onClick={() => handleReply(selectedMail)}>
                                            <FontAwesomeIcon icon={faReply} />
                                          </button>
                                          <button className="btn btn-light" onClick={() => handleForward(selectedMail)}>
                                            <FontAwesomeIcon icon={faForward} />
                                          </button>
                                          <button className="btn btn-light" onClick={() => handleDelete(selectedMail.id)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                          </button>
                                          <div className="dropdown email-settings">
  <button
    className="btn btn-light dropdown-toggle"
    type="button"
    id="emailSettingsMenu"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    style={{ cursor: "pointer" }}
  >
    <FontAwesomeIcon icon={faEllipsisVertical} />
  </button>
  <ul className="dropdown-menu" aria-labelledby="emailSettingsMenu">
  <li>
    <button className="dropdown-item" onClick={() => handleDelete(selectedMail.id)}>
      <FontAwesomeIcon icon={faTrash} /> Delete
    </button>
  </li>
  <li>
    <button className="dropdown-item" onClick={handlePrint}>
      <FontAwesomeIcon icon={faPrint} /> Print
    </button>
  </li>
  <li>
    <button className="dropdown-item" onClick={handleBlockSender}>
      <FontAwesomeIcon icon={faBan} /> Block Sender
    </button>
  </li>
  <li>
    <button className="dropdown-item" onClick={handleReportSpam}>
      <FontAwesomeIcon icon={faExclamationTriangle} /> Report Spam
    </button>
  </li>
  <li>
    <button className="dropdown-item" onClick={handleReportPhishing}>
      <FontAwesomeIcon icon={faFish} /> Report Phishing
    </button>
  </li>
  <li>
    <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleStarClick(e, selectedMail.id); }}>
      <FontAwesomeIcon
        icon={faStar}
        className={selectedMail?.labelIds?.includes('STARRED') ? 'text-warning' : 'text-muted'}
        title="Mark as Starred"
      /> Star
    </button>
  </li>
  <li>
    <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleImportantClick(e, selectedMail.id); }}>
      <FontAwesomeIcon
        icon={faExclamation}
        className={selectedMail?.labelIds?.includes('IMPORTANT') ? 'text-danger' : 'text-muted'}
        title="Mark as Important"
      /> Mark as Important
    </button>
  </li>
</ul>

</div>

                                        </div>
                                      </h5>
                                      <p className="email-from" style={{ fontSize: "14px", margin: "0" }}>
                                        <strong>From:</strong> {fromHeader}
                                      </p>
                                      <p className="email-date" style={{ fontSize: "12px", color: "#888" }}>
                                        <strong>Date:</strong> {new Date(dateHeader).toLocaleString()}
                                      </p>
                                    </div>
  
                                    <div className="email-content" style={{ lineHeight: "1.6", color: "#333" }}>
                                      {selectedMail.isHtml ? (
                                        <div dangerouslySetInnerHTML={{ __html: selectedMail.decodedMessage }} />
                                      ) : (
                                        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                                          {selectedMail.decodedMessage}
                                        </pre>
                                      )}
                                    </div>
  
                                    <div className="email-actions mt-4 d-flex gap-3">
                                      <button className="btn btn-light" onClick={() => handleReply(selectedMail)}>
                                        <FontAwesomeIcon icon={faReply} /> Reply
                                      </button>
                                      <button className="btn btn-light" onClick={() => handleForward(selectedMail)}>
                                        <FontAwesomeIcon icon={faForward} /> Forward
                                      </button>
                                      <button className="btn btn-light" onClick={() => handleDelete(selectedMail.id)}>
                                        <FontAwesomeIcon icon={faTrash} /> Delete
                                      </button>
                                    </div>
                                  </div>
                                </td>
                                <td></td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">No emails found.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
  
                <div className="pagination-controls">
                  <button
                    onClick={paginatePrev}
                    disabled={currentPage === 1}
                    className="btn btn-secondary"
                  >
                    Previous
                  </button>
                  <span className="mx-2">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={paginateNext}
                    disabled={currentPage === totalPages}
                    className="btn btn-secondary"
                  >
                    Next
                  </button>
                </div>
  
                <button className="btn btn-primary mb-3" onClick={handleGoogleSignIn}>
                  Sign in with Google
                </button>
              </div>
            ) : activeTab === "help-faqs" ? (
              <FAQ />
            ) : activeTab === "job-help" ? (
              <JobHelp />
            ) : activeTab === "settings" ? (
              <Settings
                triggerPopupMessage={triggerPopupMessage}
                setActiveTab={setActiveTab}
                setIsEditing={setIsEditing}
              />
            ) : null}
          </div>
        </div>
      </div>
  
      {popupMessage && (
        <div className="popup">
          <p>{popupMessage}</p>
        </div>
      )}
      {isEditing && <EditDetails setIsEditing={setIsEditing} />}
      {selectedMail && (
        <div className="actions mt-2">
          <button className="btn btn-light" onClick={() => handleReply(selectedMail)}>
            <FontAwesomeIcon icon={faReply} /> Reply
          </button>
          <button className="btn btn-light" onClick={() => handleForward(selectedMail)}>
            <FontAwesomeIcon icon={faForward} /> Forward
          </button>
        </div>
      )}
    </>
  );
  
};

export default LoggedIn;
