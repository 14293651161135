import React from 'react';

const Roadmap = () => {
  return (
    <div className="roadmap-container">
      <h1>U-Mail Roadmap: The Future of Email Communication</h1>
      <p>The future of U-Mail is incredibly bright. After our initial basic Gmail-only launch, we have ambitious plans that are well within our reach. Your future with U-Mail is arriving faster than you can imagine & by the end of 2024 these phases will be completed & the next round will be available! Thank you for being a vital part of U-Mail. Together, we are poised to achieve greatness, and we are proud of our journey ahead!</p>
      
      <section className="phase">
        <h2>Phase 1: Revolutionize Your Inbox</h2>
        <ul>
          <li>
            <strong>Multi-Platform Support:</strong> 
            <ul>
              <li><strong>Seamless Integration:</strong> Connect with both Gmail and Microsoft accounts effortlessly.</li>
              <li><strong>Unified Experience:</strong> Manage all your emails in one powerful platform.</li>
              <li><strong>Mobile Platforms:</strong>
                <ul>
                  <li><strong>iOS and Android Apps:</strong> Launch robust mobile applications to ensure a smooth experience on both iOS and Android devices.</li>
                  <li><strong>Responsive Design:</strong> Ensure the mobile apps are user-friendly, with intuitive interfaces and responsive design for easy navigation.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <strong>Enhanced Email Management:</strong>
            <ul>
              <li><strong>Effortless Organization:</strong> View and manage your last 100 primary inbox emails for quick access and response.</li>
              <li><strong>Smart Sorting:</strong> Automatically categorize your emails for a clutter-free inbox.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="phase">
        <h2>Phase 2: Express Yourself Like Never Before</h2>
        <ul>
          <li>
            <strong>Dynamic Email Tones:</strong>
            <ul>
              <li><strong>Beyond Professional:</strong> Choose from tones like casual, funny, and more to match your message's mood.</li>
              <li><strong>Personal Touch:</strong> Make every email feel unique with tone customization.</li>
            </ul>
          </li>
          <li>
            <strong>Emotion-Driven Communication:</strong>
            <ul>
              <li><strong>Mood Settings:</strong> Set the emotional tone of your emails with our innovative mood options.</li>
              <li><strong>Template Variety:</strong> Utilize mood-based templates to convey the right feelings.</li>
            </ul>
          </li>
        </ul>
      </section>
      
      <section className="phase">
        <h2>Phase 3: Break Language Barriers</h2>
        <ul>
          <li>
            <strong>Instant Translation:</strong>
            <ul>
              <li><strong>Global Communication:</strong> Translate emails into multiple languages instantly. Connect your business to suppliers worldwide, breaking down language barriers and enabling seamless communication like never before.</li>
              <li><strong>Context Preservation:</strong> Ensure translations maintain the original intent and context of your message, providing clear and accurate communication.</li>
              <li><strong>Language Customization:</strong> Tailor translations to fit regional dialects and specific industry terminology.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="phase">
  <h2>Phase 4: Exciting Integrations</h2>
  <ul>
    <li>
      <strong>Google Calendar Integration:</strong>
      <ul>
        <li><strong>Seamless Scheduling:</strong> Sync your emails with Google Calendar to schedule meetings and set reminders directly from U-Mail.</li>
        <li><strong>Stay Organized:</strong> Manage your calendar events and email communications in one unified platform.</li>
      </ul>
    </li>
    <li>
      <strong>Google Chrome Extension:</strong>
      <ul>
        <li><strong>Instant Access:</strong> Access U-Mail features directly from your browser with our upcoming Chrome extension.</li>
        <li><strong>Quick Actions:</strong> Perform email tasks quickly and efficiently without leaving your current tab.</li>
        <li><strong>Enhanced Productivity:</strong> Utilize the full power of U-Mail's features within your browser for an unparalleled email experience.</li>
        <li><strong>Seamless Integration:</strong> Enjoy a smooth and integrated experience that keeps you connected and efficient.</li>
      </ul>
    </li>
  </ul>
</section>

      
      <section className="phase">
        <h2>Phase 5: All Your Messages in One Place</h2>
        <ul>
          <li>
            <strong>Integrated Messaging Services:</strong>
            <ul>
              <li><strong>Social Synergy:</strong> Connect with Instagram Chat, Messenger, LinkedIn & more directly from U-Mail.</li>
              <li><strong>Unified Inbox:</strong> Access all your conversations in one place, making communication seamless.</li>
              <li><strong>Centralized Management:</strong> Organize all your communication channels effortlessly in one platform.</li>
              <li><strong>AI-Powered Organization:</strong> Our advanced AI helps you sort and prioritize messages from various platforms, ensuring you never miss an important chat.</li>
              <li><strong>Flexible Messaging:</strong> Choose where to send each type of message—whether it's an email, chat, or SMS—across any platform directly from U-Mail.</li>
            </ul>
          </li>
          <li>
            <strong>Text Messaging Made Simple:</strong>
            <ul>
              <li><strong>Direct SMS:</strong> Send and receive text messages from within the app.</li>
              <li><strong>Contact Sync:</strong> Seamlessly sync your contacts for effortless messaging.</li>
            </ul>
          </li>
        </ul>
      </section>

      
      <section className="phase">
        <h2>Phase 6: AI-Powered Perfection</h2>
        <ul>
          <li>
            <strong>AI Chatbot Assistance:</strong>
            <ul>
              <li><strong>Your Personal Writing Assistant:</strong> Our custom AI chatbot helps you craft the perfect letter, every time.</li>
              <li><strong>Smart Suggestions:</strong> Get AI-driven content, tone, and structure suggestions to enhance your emails.</li>
              <li><strong>Automated Responses:</strong> Utilize AI to generate quick, context-aware responses to common queries.</li>
            </ul>
          </li>
        </ul>
      </section>
      
      <section className="phase">
        <h2>Phase 7: Sleek, Fast, and Secure</h2>
        <ul>
          <li>
            <strong>User Interface Overhaul:</strong>
            <ul>
              <li><strong>Modern Design:</strong> Experience a sleek, intuitive interface that’s easy on the eyes and a joy to use.</li>
              <li><strong>Responsive Layout:</strong> Enjoy U-Mail on any device with a design that adapts to your screen.</li>
            </ul>
          </li>
          <li>
            <strong>Top-Notch Security:</strong>
            <ul>
              <li><strong>Ironclad Protection:</strong> Your data’s safety is our top priority with enhanced security features.</li>
              <li><strong>Performance Boost:</strong> Enjoy faster load times and smoother performance, no matter how many emails you have.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="phase">
  <h2>Phase 8: Ghosting Feature</h2>
  <ul>
    <li>
      <strong>Ghosting Feature:</strong>
      <ul>
        <li><strong>Stay Focused:</strong> Add unwanted contacts to a block list and let our AI send kind automated replies, tailored to your selected moods and tones.</li>
        <li><strong>Customizable Responses:</strong> Customize your automated responses or let our AI handle it for you.</li>
        <li><strong>Summary Reports:</strong> Choose how often you want to receive summaries of these interactions: daily, weekly, or monthly. Manage your time effectively with U-Mail.</li>
        <li><strong>Enhanced Control:</strong> This feature will not only enhance user control over their communications but also align with the overall goal of U-Mail to provide smart, efficient, and user-friendly email management.</li>
      </ul>
    </li>
  </ul>
</section>


     
    </div>
  );
}

export default Roadmap;
