import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Add Navigate for redirection
import Home from './pages/home';
import Login from './pages/login';
import Register from './pages/register';
import LoggedIn from './pages/loggedIn';
import About from './pages/about';
import Join from './pages/join';
import Price from './pages/pricing';
import FAQ from './pages/faq';
import Roadmap from './pages/roadMap';
import Header from './pages/Header';
import Footer from './pages/Footer';
import PrivacyPage from './pages/PrivacyPage';
import TermsAndServices from './pages/TermsAndServices';
import XSSProtection from './pages/XSSProtection';
import Inbox from './pages/loggedIn/Inbox'; // Import your Inbox component

function App() {
  const [accessToken, setAccessToken] = useState('');

  return (
    <XSSProtection>
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} /> 
            <Route path="/login" element={<Login setAccessToken={setAccessToken} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logged-in" element={<LoggedIn accessToken={accessToken} />} />
            <Route path="/inbox" element={<Inbox accessToken={accessToken} />} /> {/* New route for Inbox */}
            <Route path="/about" element={<About />} />
            <Route path="/join" element={<Register />} />
            <Route path="/pricing" element={<Price />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/TermsAndServices" element={<TermsAndServices />} />
            <Route path="/roadmap" element={<Roadmap />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </XSSProtection>
  );
}

export default App;
