import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const DictionaryAssistant = ({ setPopupMessageWithTimeout, mailContent, setMailContent }) => {
  const [showDictionaryModal, setShowDictionaryModal] = useState(false);
  const [selectedWord, setSelectedWord] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);

  const getHighlightedText = () => {
    let selectedText = '';
    if (window.getSelection) {
      selectedText = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== 'Control') {
      selectedText = document.selection.createRange().text;
    }
    return selectedText;
  };

  const fetchDefinition = async () => {
    const selectedText = getHighlightedText();
    if (!selectedText.trim()) {
      setPopupMessageWithTimeout("Please highlight a word to find its definition.");
      return;
    }
    setSelectedWord(selectedText);
    setShowDictionaryModal(true);
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/dictionary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ word: selectedText })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Formatted response:', data.result); // Log the result to ensure it's correct
      setResult(data.result || 'No result found.');
      setLoading(false);
    } catch (error) {
      console.error("Error fetching definition:", error);
      setResult('No result found.');
      setLoading(false);
    }
  };

  return (
    <>
      <button className="btn btn-primary btn-sm get-in-touch" type="button" onClick={fetchDefinition}>Dictionary</button>

      {/* Modal for Dictionary */}
      <div className={`modal fade ${showDictionaryModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showDictionaryModal ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Definition of "{selectedWord}"</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowDictionaryModal(false)}></button>
            </div>
            <div className="modal-body">
              {loading ? (
                <div className="spinner-overlay">
                  <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                </div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: result.replace(/\n/g, '<br />') }} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DictionaryAssistant;
