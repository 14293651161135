import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const TermsAndServices = () => {
  return (
    <div className="terms-container mt-5">
      <div className="card shadow-sm terms-card">
        <div className="card-header text-white text-center">
          <h1 className="h3">Terms and Services</h1>
        </div>
        <div className="card-body p-4">
          <section className="mb-4">
            <h2 className="h4">1. Introduction</h2>
            <p>Welcome to U-Mail. By accessing or using our service, you agree to be bound by these Terms and Services. If you do not agree with any part of these terms, you must not use our service.</p>
          </section>

          <section className="mb-4">
            <h2 className="h4">2. Definitions</h2>
            <ul>
              <li><strong>Service:</strong> The U-Mail application provided by us.</li>
              <li><strong>User:</strong> Any person or entity using the Service.</li>
              <li><strong>Content:</strong> Any information, text, graphics, or other materials uploaded, downloaded, or appearing on the Service.</li>
            </ul>
          </section>

          <section className="mb-4">
            <h2 className="h4">3. User Responsibilities</h2>
            <ul>
              <li>Users must provide accurate information during registration and maintain the confidentiality of their account credentials.</li>
              <li>Users must not use the Service for any unlawful or prohibited activities.</li>
              <li>Users are responsible for all activities that occur under their account.</li>
            </ul>
          </section>

          <section className="mb-4">
            <h2 className="h4">4. Acceptable Use</h2>
            <ul>
              <li>Users must not use the Service to send unsolicited emails or spam.</li>
              <li>Users must not upload or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable.</li>
              <li>Users must not interfere with or disrupt the integrity or performance of the Service.</li>
            </ul>
          </section>

          <section className="mb-4">
            <h2 className="h4">5. Privacy</h2>
            <p>Your use of the Service is also governed by our Privacy Policy, which outlines how we collect, use, and protect your information. By using the Service, you agree to the terms of the Privacy Policy.</p>
          </section>

          <section className="mb-4">
            <h2 className="h4">6. Intellectual Property</h2>
            <p>All intellectual property rights in the Service and its content are owned by us or our licensors. Users must not reproduce, distribute, or create derivative works from any part of the Service without our prior written consent.</p>
          </section>

          <section className="mb-4">
            <h2 className="h4">7. Termination</h2>
            <p>We reserve the right to suspend or terminate your access to the Service at our sole discretion, without notice, for conduct that we believe violates these Terms and Services or is harmful to other users of the Service, us, or third parties, or for any other reason.</p>
          </section>

          <section className="mb-4">
            <h2 className="h4">8. Limitation of Liability</h2>
            <p>The Service is provided on an "as is" and "as available" basis. We do not warrant that the Service will be uninterrupted, error-free, or free from viruses or other harmful components. We are not liable for any damages arising from the use of or inability to use the Service.</p>
          </section>

          <section className="mb-4">
            <h2 className="h4">9. Indemnification</h2>
            <p>Users agree to indemnify and hold us harmless from any claims, damages, losses, liabilities, and expenses (including legal fees) arising out of their use of the Service or violation of these Terms and Services.</p>
          </section>

          <section className="mb-4">
            <h2 className="h4">10. Changes to the Terms</h2>
            <p>We may modify these Terms and Services from time to time. We will notify users of any changes by posting the new terms on our website. Continued use of the Service after such changes will constitute acceptance of the new terms.</p>
          </section>

          <section className="mb-4">
            <h2 className="h4">11. Governing Law</h2>
            <p>These Terms and Services are governed by and construed in accordance with the laws of The United States Of America. Any disputes arising out of or in connection with these terms will be subject to the exclusive jurisdiction of the courts of  United States Of America.</p>
          </section>

          <section className="mb-4">
            <h2 className="h4">12. Contact Us</h2>
            <p>If you have any questions about these Terms and Services, please contact us at:</p>
            <ul>
              <li>Email: <a href="mailto:support@u-mail.ai">support@u-mail.ai</a></li>

            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndServices;
