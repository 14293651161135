import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faUnderline, faStrikethrough, faAlignLeft, faAlignCenter, faAlignRight, faListOl, faListUl } from '@fortawesome/free-solid-svg-icons';

const EditOptions = ({ handleSignatureToggle, useSignature, setMailContent }) => {
  const applyStyle = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  return (
    <div className="form-field col-lg-12">
    <div className="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
      <div className="btn-group me-2" role="group" aria-label="First group">
        <button type="button" className={`btn ${useSignature ? 'btn-primary' : 'btn-light'}`} onClick={handleSignatureToggle}>
          Signature
        </button>
        <button type="button" className="btn btn-light" onClick={() => applyStyle('bold')}>
          <FontAwesomeIcon icon={faBold} /> 
        </button>
        <button type="button" className="btn btn-light" onClick={() => applyStyle('italic')}>
          <FontAwesomeIcon icon={faItalic} /> 
        </button>
        <button type="button" className="btn btn-light" onClick={() => applyStyle('underline')}>
          <FontAwesomeIcon icon={faUnderline} /> 
        </button>
        <button type="button" className="btn btn-light" onClick={() => applyStyle('strikeThrough')}>
          <FontAwesomeIcon icon={faStrikethrough} />
        </button>
        <div className="btn btn-light">
          <input
            type="color"
            className="form-control form-control-color"
            style={{ border: 'none' }}
            onChange={(e) => applyStyle('foreColor', e.target.value)}
          />
        </div>
      </div>
      <div className="btn-group me-2" role="group" aria-label="Second group">
        <button type="button" className="btn btn-light" onClick={() => applyStyle('justifyLeft')}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </button>
        <button type="button" className="btn btn-light" onClick={() => applyStyle('justifyCenter')}>
          <FontAwesomeIcon icon={faAlignCenter} />
        </button>
        <button type="button" className="btn btn-light" onClick={() => applyStyle('justifyRight')}>
          <FontAwesomeIcon icon={faAlignRight} />
        </button>
      </div>
      <div className="btn-group me-2" role="group" aria-label="Third group">
        <button type="button" className="btn btn-light" onClick={() => applyStyle('insertOrderedList')}>
          <FontAwesomeIcon icon={faListOl} />
        </button>
        <button type="button" className="btn btn-light" onClick={() => applyStyle('insertUnorderedList')}>
          <FontAwesomeIcon icon={faListUl} />
        </button>
      </div>
      <select className="form-select d-inline-block w-auto" onChange={(e) => applyStyle('fontSize', e.target.value)}>
        <option value="">Font Size</option>
        <option value="1" style={{ fontSize: '10px' }}>Small</option>
        <option value="3" style={{ fontSize: '16px' }}>Normal</option>
        <option value="5" style={{ fontSize: '24px' }}>Large</option>
      </select>
      <select className="form-select d-inline-block w-auto ms-2" onChange={(e) => applyStyle('fontName', e.target.value)}>
        <option value="">Font Family</option>
        <option value="Arial" style={{ fontFamily: 'Arial' }}>Arial</option>
        <option value="Courier New" style={{ fontFamily: 'Courier New' }}>Courier New</option>
        <option value="Georgia" style={{ fontFamily: 'Georgia' }}>Georgia</option>
        <option value="Times New Roman" style={{ fontFamily: 'Times New Roman' }}>Times New Roman</option>
        <option value="Verdana" style={{ fontFamily: 'Verdana' }}>Verdana</option>
        <option value="Comic Sans MS" style={{ fontFamily: 'Comic Sans MS' }}>Comic Sans MS</option>
        <option value="Trebuchet MS" style={{ fontFamily: 'Trebuchet MS' }}>Trebuchet MS</option>
        <option value="Lucida Console" style={{ fontFamily: 'Lucida Console' }}>Lucida Console</option>
        <option value="Impact" style={{ fontFamily: 'Impact' }}>Impact</option>
        <option value="Tahoma" style={{ fontFamily: 'Tahoma' }}>Tahoma</option>
        <option value="Arial Black" style={{ fontFamily: 'Arial Black' }}>Arial Black</option>
        <option value="Palatino Linotype" style={{ fontFamily: 'Palatino Linotype' }}>Palatino Linotype</option>
        <option value="Garamond" style={{ fontFamily: 'Garamond' }}>Garamond</option>
        <option value="Bookman" style={{ fontFamily: 'Bookman' }}>Bookman</option>
        <option value="Trebuchet MS" style={{ fontFamily: 'Trebuchet MS' }}>Trebuchet MS</option>
        <option value="Lucida Sans" style={{ fontFamily: 'Lucida Sans' }}>Lucida Sans</option>
        <option value="Gill Sans" style={{ fontFamily: 'Gill Sans' }}>Gill Sans</option>
        <option value="Century Gothic" style={{ fontFamily: 'Century Gothic' }}>Century Gothic</option>
        <option value="Franklin Gothic Medium" style={{ fontFamily: 'Franklin Gothic Medium' }}>Franklin Gothic Medium</option>
        <option value="Candara" style={{ fontFamily: 'Candara' }}>Candara</option>
        <option value="Segoe UI" style={{ fontFamily: 'Segoe UI' }}>Segoe UI</option>
      </select>
    </div>
    </div>
  );
};

export default EditOptions;
