import React, { useState, useRef, useEffect } from "react";
import {
  processSpecialCommands,
  updateFormFields,
} from "../scripts/speechUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faXmark,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { auth, db } from "../firebase-config";
import { getDoc, doc } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import SynonymAssistant from "./UMailContext/SynonymAssistant";
import SpellCheckAssistant from "./UMailContext/SpellCheckAssistant";
import LanguageAssistant from "./UMailContext/LanguageAssistant";
import TextRefinementAssistant from "./UMailContext/TextRefinementAssistant";
import EditOptions from "./UMailContext/NormalEditOptions";
import MoodToneAssistant from "./UMailContext/MoodToneAssistant";
import IndustryAssistant from "./UMailContext/IndustryAssistant";
import ZoomScheduler from "./UMailContext/ZoomScheduler";
import GoogleCalendarScheduler from "./UMailContext/GoogleCalendarScheduler";
import WritingStyleAssistant from "./UMailContext/WritingStyleAssistant";
import DictionaryAssistant from "./UMailContext/DictionaryAssistant";
import TemplateUpdateModal from "./UMailContext/TemplateUpdateModal";
import axios from 'axios'; // For sending email using Gmail API

const fieldKeywords = {
  to: ["email", "receiver"],
  subject: ["subject"],
  message: ["message"],
};

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const NewMail = ({
  isListening,
  setPopupMessage,
  useSignature,
  setUseSignature,
  signature,
  setSignature,
  setMailContent,
  mailContent,
  userEmail,
  accessToken // Pass accessToken from the parent component
}) => {
  const recognition = useRef(new SpeechRecognition());
  const [enhancedText, setEnhancedText] = useState("");
  const [enhanceCount, setEnhanceCount] = useState(0);
  const [lastSentText, setLastSentText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showMoodToneModal, setShowMoodToneModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showIndustryModal, setShowIndustryModal] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedMood, setSelectedMood] = useState("Neutral");
  const [selectedTone, setSelectedTone] = useState("Formal");
  const [showWritingStyleModal, setShowWritingStyleModal] = useState(false);
  const [resumeUrl, setResumeUrl] = useState("");
  const [user, setUser] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("Coral"); // Default template
  const [showTemplateModal, setShowTemplateModal] = useState(false); // Modal state
  const [resumeGenerated, setResumeGenerated] = useState(false);

  recognition.current.continuous = true;
  recognition.current.interimResults = true;
  recognition.current.lang = "en-US";

  useEffect(() => {
    const handleResult = (event) => {
      let transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      const cleanTranscript = transcript;
      const words = cleanTranscript.split(" ");
      const processedTranscript = processSpecialCommands(words);

      const fieldsUpdate = updateFormFields(processedTranscript, fieldKeywords);
      setMailContent((prev) => ({
        ...prev,
        ...fieldsUpdate,
      }));
      setEnhancedText(processedTranscript);

      // Update the content of the message element
      document.getElementById("message").innerText = processedTranscript;
    };

    recognition.current.onresult = handleResult;

    if (isListening) {
      recognition.current.start();
    } else {
      recognition.current.stop();
    }

    return () => {
      recognition.current.stop();
    };
  }, [isListening]);

  useEffect(() => {
    if (mailContent.message) {
      // Pre-fill the message body in the editable div when mailContent.message is updated
      document.getElementById("message").innerText = mailContent.message;
    }
  }, [mailContent.message]);

  const handleInputChange = (field, value) => {
    if (field === "from" || field === "to") {
      value = value.replace(/\s*@|\s*(?=@)/g, "@");
    }

    setMailContent((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const clearForm = () => {
    setMailContent({ id: null, from: "", to: "", subject: "", message: "" });
    document.getElementById("message").innerText = "";
  };

  const enhanceText = async (text) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/text-enhance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt: text,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setLoading(false);
      return data.text ? data.text.trim() : "No text generated.";
    } catch (error) {
      setLoading(false);
      console.error("Error enhancing text:", error);
      throw error; // Re-throw to handle it later
    }
  };

  const customEnhanceText = async (text, mood, tone) => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/custom-enhance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
          mood: mood,
          tone: tone,
        }),
      }
    );

    if (!response.ok) {
      setLoading(false);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setLoading(false);
    console.log(data); // Log the response data for debugging
    return data.text ? data.text.trim() : "No text generated.";
  };

  const displayEnhancedText = async () => {
    const messageContent = document.getElementById("message").innerText;

    if (!messageContent.trim()) {
      setPopupMessageWithTimeout("Add some text please!");
      return;
    }

    try {
      const enhanced = await enhanceText(messageContent);
      const splitIndex = enhanced?.indexOf("\n");
      const enhancedSubject =
        splitIndex !== -1 ? enhanced.substring(0, splitIndex).trim() : enhanced;
      const enhancedMessage =
        splitIndex !== -1 ? enhanced.substring(splitIndex + 1).trim() : "";

      setMailContent((prev) => ({
        ...prev,
        subject: enhancedSubject,
        message: `\n\n${enhancedMessage}`,
      }));
      document.getElementById(
        "message"
      ).innerText = `Hello,\n\n${enhancedMessage}`;
      setLastSentText(messageContent);
      setEnhanceCount((prevCount) => prevCount + 1);
    } catch (error) {
      setMailContent((prev) => ({
        ...prev,
        message: "An error occurred while enhancing the text.",
      }));
      console.error("Error enhancing text:", error);
    }
  };

  const displayCustomEnhancedText = async () => {
    const messageContent = document.getElementById("message").innerText;

    if (!messageContent.trim()) {
      setPopupMessageWithTimeout("Add some text homie!");
      return;
    }

    try {
      const enhanced = await customEnhanceText(
        messageContent,
        selectedMood,
        selectedTone
      );
      const splitIndex = enhanced?.indexOf("\n");
      const enhancedSubject =
        splitIndex !== -1 ? enhanced.substring(0, splitIndex).trim() : enhanced;
      const enhancedMessage =
        splitIndex !== -1 ? enhanced.substring(splitIndex + 1).trim() : "";

      setMailContent((prev) => ({
        ...prev,
        subject: enhancedSubject,
        message: `Hello,\n\n${enhancedMessage}`,
      }));
      document.getElementById("message").innerText = `\n\n${enhancedMessage}`;
      setLastSentText(messageContent);
    } catch (error) {
      setMailContent((prev) => ({
        ...prev,
        message: "An error occurred while enhancing the text.",
      }));
      console.error("Error enhancing text:", error);
    }
  };

  const setPopupMessageWithTimeout = (message) => {
    setPopupMessage(message);
    setTimeout(() => setPopupMessage(""), 4000);
  };

  const handleSignatureToggle = async () => {
    if (!auth.currentUser) {
      console.error("No authenticated user. Please log in.");
      setPopupMessageWithTimeout("No authenticated user. Please log in.");
      return;
    }

    const newUseSignature = !useSignature;
    setUseSignature(newUseSignature);

    if (newUseSignature) {
      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userSignature = userDoc.data().signature || "";
          setSignature(userSignature);
          setMailContent((prev) => ({
            ...prev,
            message: `${prev.message}\n\n${userSignature}`,
          }));
          document.getElementById(
            "message"
          ).innerText += `\n\n${userSignature}`;
        }
      } catch (error) {
        console.error("Error fetching user signature:", error);
      }
    } else {
      const messageContent = document.getElementById("message").innerText;
      setMailContent((prev) => ({
        ...prev,
        message: messageContent.replace(`\n\n${signature}`, ""),
      }));
      document.getElementById("message").innerText = messageContent.replace(
        `\n\n${signature}`,
        ""
      );
    }
  };

  const handleIndustryChange = async (industry) => {
    setSelectedIndustry(industry);
    setShowIndustryModal(false);

    const messageContent = document.getElementById("message").innerText;
    if (!messageContent.trim()) {
      setPopupMessageWithTimeout("Add some text to enhance!");
      return;
    }

    try {
      const enhanced = await enhanceIndustryText(messageContent, industry);
      const splitIndex = enhanced?.indexOf("\n");
      const enhancedSubject =
        splitIndex !== -1 ? enhanced.substring(0, splitIndex).trim() : enhanced;
      const enhancedMessage =
        splitIndex !== -1 ? enhanced.substring(splitIndex + 1).trim() : "";

      setMailContent((prev) => ({
        ...prev,
        subject: enhancedSubject,
        message: enhancedMessage,
      }));
      document.getElementById("subject").value = enhancedSubject;
      document.getElementById("message").innerText = enhancedMessage;
    } catch (error) {
      console.error("Error enhancing text with industry lingo:", error);
    }
  };

  const enhanceIndustryText = async (text, industry) => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/industry-enhance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
          industry: industry,
        }),
      }
    );

    if (!response.ok) {
      setLoading(false);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setLoading(false);
    console.log(data); // Log the response data for debugging
    return data.text ? data.text.trim() : "No text generated.";
  };

  useEffect(() => {
    const messageElement = document.getElementById("message");
  
    const handleCopy = (event) => {
      if (event.target.id === "message") {
        event.preventDefault();
        setPopupMessageWithTimeout("You can't copy text from U-Mail.");
      }
    };
  
    if (messageElement) {
      messageElement.addEventListener("copy", handleCopy);
    }
  
    return () => {
      if (messageElement) {
        messageElement.removeEventListener("copy", handleCopy);
      }
    };
  }, []);
  
  const handleSendEmail = async () => {
    setLoading(true);
    
    try {
      const messageContent = document.getElementById('message').innerText; // Get the current content of the message element
  
      const response = await axios.post('http://localhost:3001/api/send-email', {
        to: mailContent.to,
        subject: mailContent.subject,
        message: messageContent, // Use the content from id=message
        accessToken: accessToken,  // Use the passed OAuth2 access token instead of localStorage
        userEmail: userEmail       // Use the passed logged-in user's email
      });
  
      if (response.data.success) {
        setPopupMessage('Email sent successfully!');
        setTimeout(() => setPopupMessage(''), 3000); 
      } else {
        setPopupMessage('Failed to send email.');
        setTimeout(() => setPopupMessage(''), 3000); 
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setPopupMessage('Failed to send email.');
      setTimeout(() => setPopupMessage(''), 3000); 
    }
    
    setLoading(false);
  };
  
  
  
  

  return (
    <div>
      <form className="contact-form row" onSubmit={(e) => e.preventDefault()}>
        <div className="form-field col-lg-6">
          <input
            id="from"
            className="input-text js-input"
            placeholder="From"
            type="text"
            value={userEmail}
            readOnly
            required
          />
        </div>
        <div className="form-field col-lg-6">
          <input
            id="email"
            className="input-text js-input"
            placeholder="Receiver Email"
            type="email"
            value={mailContent.to}
            onChange={(e) => handleInputChange("to", e.target.value)}
            required
          />
        </div>
        <div className="form-field col-lg-6">
          <input
            id="subject"
            className="input-text js-input"
            placeholder="Subject"
            type="text"
            value={mailContent.subject}
            onChange={(e) => handleInputChange("subject", e.target.value)}
            required
          />
        </div>
        <EditOptions
          handleSignatureToggle={handleSignatureToggle}
          useSignature={useSignature}
          setMailContent={setMailContent}
        />

        <div className="accordion" id="enhanceAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                AI enhancement Options
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#enhanceAccordion"
            >
              <div className="accordion-body">
                <div className="d-flex flex-wrap align-items-center mb-3 gap-2">
                  <button
                    className="btn btn-primary btn-sm get-in-touch"
                    type="button"
                    onClick={() => setShowMoodToneModal(true)}
                  >
                    Enhance with AI
                  </button>
                  <LanguageAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                    mailContent={mailContent}
                    setMailContent={setMailContent}
                  />
                  <button
                    className="btn btn-primary btn-sm get-in-touch"
                    type="button"
                    onClick={() => setShowIndustryModal(true)}
                  >
                    Industry Specific Lingo
                  </button>

                  <button
                    className="btn btn-primary btn-sm get-in-touch"
                    onClick={() => setShowWritingStyleModal(true)}
                  >
                    Writing Styles
                  </button>

                  <TextRefinementAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                  />

                  <SynonymAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                    mailContent={mailContent}
                    setMailContent={setMailContent}
                  />

                  <SpellCheckAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                  />

                  <DictionaryAssistant
                    setPopupMessageWithTimeout={setPopupMessageWithTimeout}
                    mailContent={mailContent}
                    setMailContent={setMailContent}
                  />
                  <button
                    className="btn btn-outline-primary btn-sm"
                    type="button"
                    onClick={() => setShowModal(true)}
                  >
                    See Before Text
                  </button>

                  <div>
                    {resumeGenerated && (
                      <TemplateUpdateModal
                        showTemplateModal={showTemplateModal}
                        setShowTemplateModal={setShowTemplateModal}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="message"
          className="input-text js-input"
          contentEditable="true"
        ></div>
      </form>

      <div
        className="btn-group floatR"
        role="group"
        aria-label="Basic outlined example"
      >
        <div className="d-flex flex-wrap align-items-center gap-2">
          <ZoomScheduler
            setPopupMessageWithTimeout={setPopupMessageWithTimeout}
            mailContent={mailContent}
            setMailContent={setMailContent}
            userEmail={userEmail}
          />
        </div>

        <div className="d-flex flex-wrap align-items-center gap-2">
          <GoogleCalendarScheduler
            setPopupMessageWithTimeout={setPopupMessageWithTimeout}
            mailContent={mailContent}
            setMailContent={setMailContent}
            userEmail={userEmail}
          />
        </div>
        <button onClick={handleSendEmail} className="btn btn-outline-primary">
      <FontAwesomeIcon icon={faPaperPlane} /> Send
    </button>

        <button id="save" className="btn btn-outline-primary" type="button">
          <FontAwesomeIcon icon={faArrowDown} /> Save
        </button>
        <button
          id="clear"
          className="btn btn-outline-primary"
          type="button"
          onClick={clearForm}
        >
          <FontAwesomeIcon icon={faXmark} /> Clear
        </button>
      </div>

      {/* Modal for Before Text */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Text Sent to U-Mail AI Enhancer</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              ></button>
            </div>
            <div className="modal-body">{lastSentText}</div>
          </div>
        </div>
      </div>

      {/* Modal for Mood and Tone */}
      {showMoodToneModal && (
        <MoodToneAssistant
          setSelectedMood={setSelectedMood}
          setSelectedTone={setSelectedTone}
          displayCustomEnhancedText={displayCustomEnhancedText}
          setShowMoodToneModal={setShowMoodToneModal}
          displayEnhancedText={displayEnhancedText}
        />
      )}

      {/* Modal for Writing Style Assistant */}
      {showWritingStyleModal && (
        <WritingStyleAssistant
          setPopupMessageWithTimeout={setPopupMessageWithTimeout}
          setShowWritingStyleModal={setShowWritingStyleModal}
        />
      )}

      {/* Modal for Industry Selection */}
      <IndustryAssistant
        handleIndustryChange={handleIndustryChange}
        showIndustryModal={showIndustryModal}
        setShowIndustryModal={setShowIndustryModal}
        loading={loading}
      />
    </div>
  );
};

export default NewMail;
