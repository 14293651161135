import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { diffWords } from 'diff'; // Use the named export diffWords

const GrammarSpellCheckAssistant = ({ setPopupMessageWithTimeout }) => {
  const [loading, setLoading] = useState(false);
  const [diffText, setDiffText] = useState('');
  const [showDiffModal, setShowDiffModal] = useState(false);

  const getHighlightedText = () => {
    let selectedText = '';
    if (window.getSelection) {
      selectedText = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== 'Control') {
      selectedText = document.selection.createRange().text;
    }
    return selectedText;
  };

  const fetchGrammarSpellCheck = async () => {
    const text = getHighlightedText();
    if (!text.trim()) {
      setPopupMessageWithTimeout("Please highlight some text to check grammar and spelling.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/grammar-spell-check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const diff = diffWords(text, data.correctedText); // Use diffWords for word-level differences
      const formattedDiff = diff.map((part, index) => {
        const spanStyle = part.added ? 'background-color: yellow; color: green;' :
                          part.removed ? 'background-color: yellow; color: red;' : '';
        return `<span key=${index} style="${spanStyle}">${part.value}</span>`;
      }).join('');

      setDiffText(formattedDiff);
      setShowDiffModal(true);
    } catch (error) {
      console.error("Error performing grammar and spell check:", error);
      setPopupMessageWithTimeout("Error performing grammar and spell check.");
    } finally {
      setLoading(false);
    }
  };

  const applyCorrections = () => {
    document.getElementById('message').innerHTML = diffText.replace(/<[^>]*>/g, ''); // Remove HTML tags
    formatMessageAsEmail();
    setShowDiffModal(false);
  };

  const formatMessageAsEmail = () => {
    const messageContent = document.getElementById('message').innerText;
    const emailFormat = `Dear [Recipient],\n\n${messageContent.trim()}\n\nBest regards,\n[Your Name]`;
    document.getElementById('message').innerText = emailFormat;
  };

  return (
    <>
      <button className="btn btn-primary btn-sm get-in-touch" type="button" onClick={fetchGrammarSpellCheck} disabled={loading}>
        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Grammar & Spell Check'}
      </button>

      {showDiffModal && (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Grammar & Spell Check</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={applyCorrections}></button>
              </div>
              <div className="modal-body">
                <div dangerouslySetInnerHTML={{ __html: diffText }}></div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={applyCorrections}>Apply Corrections</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GrammarSpellCheckAssistant;
