import React, { useState } from 'react';
import JobAssistant from '../../scripts/UMailContext/JobAssistant'; // Correctly import the new JobAssistant component

const JobHelp = () => {
  const [showJobAssistantModal, setShowJobAssistantModal] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const setPopupMessageWithTimeout = (message) => {
    setPopupMessage(message);
    setTimeout(() => setPopupMessage(''), 3000); // Clear message after 3 seconds
  };

  return (
    <div className="container mt-5">
      <h1>Job Help</h1>
      <p>This section helps you generate tailored cover letters and resumes based on the job description.</p>

      {/* Button to trigger the Job Assistant modal */}
      <button className="btn btn-primary mt-4" onClick={() => setShowJobAssistantModal(true)}>
        Open Job Assistant
      </button>

      {/* Popup message for user feedback */}
      {popupMessage && (
        <div className="alert alert-info mt-3">
          {popupMessage}
        </div>
      )}

      {/* Render the Job Assistant modal if it's triggered */}
      {showJobAssistantModal && (
        <JobAssistant 
          setPopupMessageWithTimeout={setPopupMessageWithTimeout} 
          setShowJobAssistantModal={setShowJobAssistantModal} 
        />
      )}
    </div>
  );
};

export default JobHelp;
