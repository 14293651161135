import React, { useEffect } from 'react';

const XSSProtection = ({ children }) => {
  useEffect(() => {
    // const inputFields = document.querySelectorAll('input, textarea');
    // let userIp = '';
    // let deviceInfo = '';
    // let rightClickCount = 0;
    // let f12Count = 0;

    // // Function to fetch the user's IP address
    // async function fetchIpAddress() {
    //   try {
    //     const response = await fetch('https://api.ipify.org?format=json');
    //     const data = await response.json();
    //     userIp = data.ip;
    //   } catch (error) {
    //     console.error('Error fetching IP address:', error);
    //     userIp = 'unknown'; // Fallback value in case of an error
    //   }
    // }

    // // Function to get device information
    // function getDeviceInfo() {
    //   const ua = navigator.userAgent;
    //   const platform = navigator.platform;
    //   deviceInfo = `User-Agent: ${ua}, Platform: ${platform}`;
    // }

    // // Call the functions when the component mounts
    // fetchIpAddress();
    // getDeviceInfo();

    // const blockedChars = '<>;()?/{}[]#';
    // const bannedWords = ['badword1', 'badword2', 'badword3']; // Replace with actual words

    // // Function to check for blocked characters
    // function containsBlockedChars(inputValue) {
    //   return [...blockedChars].some(char => inputValue.includes(char));
    // }

    // // Function to check for banned words
    // function containsBannedWords(inputValue) {
    //   return bannedWords.some(word => inputValue.toLowerCase().includes(word));
    // }

    // // Function to sanitize input value
    // function sanitizeInputValue(inputValue) {
    //   let sanitizedValue = inputValue.split('').filter(char => !blockedChars.includes(char)).join('');
    //   bannedWords.forEach(word => {
    //     const regex = new RegExp(word, 'gi');
    //     sanitizedValue = sanitizedValue.replace(regex, ' ');
    //   });
    //   return sanitizedValue;
    // }

    // inputFields.forEach(input => {
    //   input.addEventListener('input', (event) => {
    //     const sanitizedValue = sanitizeInputValue(event.target.value);
    //     if (sanitizedValue !== event.target.value) {
    //       event.target.value = sanitizedValue;
    //     }
    //   });

    //   // Handle paste events
    //   input.addEventListener('paste', (event) => {
    //     const pastedText = (event.clipboardData || window.clipboardData).getData('text');
    //     if (containsBlockedChars(pastedText) || containsBannedWords(pastedText)) {
    //       event.preventDefault();
    //       input.value = sanitizeInputValue(pastedText);
    //     }
    //   });
    // });

    // Right-click protection
    // const handleContextMenu = (event) => {
    //   event.preventDefault();
    //   rightClickCount++;
    // };

    // F12 and DevTools protection
    // const handleKeyDown = (event) => {
    //   if (event.key === "F12" || (event.ctrlKey && event.shiftKey and event.key === 'I') || (event.metaKey and event.altKey and event.key === 'I')) {
    //     event.preventDefault();
    //     f12Count++;
    //   }
    // };

    // document.addEventListener('contextmenu', handleContextMenu);
    // document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      // document.removeEventListener('contextmenu', handleContextMenu);
      // document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  return <>{children}</>;
};

export default XSSProtection;
