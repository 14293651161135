import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeace, faSmile, faFrown, faAngry, faLaugh, faHandshake, faUserTie, faUserFriends, faGrin, faUserNurse, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const MoodToneAssistant = ({ setSelectedMood, setSelectedTone, displayCustomEnhancedText, setShowMoodToneModal, displayEnhancedText }) => {
  const [selectedMood, setLocalSelectedMood] = useState('Neutral');
  const [selectedTone, setLocalSelectedTone] = useState('Formal');
  const [customMood, setCustomMood] = useState('');
  const [customTone, setCustomTone] = useState('');

  const handleMoodChange = (value) => {
    setLocalSelectedMood(value);
    setSelectedMood(value);
    setCustomMood('');  // Clear custom input if predefined mood is selected
  };

  const handleToneChange = (value) => {
    setLocalSelectedTone(value);
    setSelectedTone(value);
    setCustomTone('');  // Clear custom input if predefined tone is selected
  };

  const handleCustomMoodChange = (event) => {
    setCustomMood(event.target.value);
    setLocalSelectedMood('Custom');
    setSelectedMood(event.target.value);
  };

  const handleCustomToneChange = (event) => {
    setCustomTone(event.target.value);
    setLocalSelectedTone('Custom');
    setSelectedTone(event.target.value);
  };

  return (
    <div className={`modal fade show`} tabIndex="-1" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowMoodToneModal(false)}></button>
          </div>
          <div className="modal-body">
            <button id='centeringClass' className="btn btn-primary btn-sm get-in-touch" type="button" onClick={() => { displayEnhancedText(); setShowMoodToneModal(false); }}>Enhance as a professional message</button>
            <p className='centered'>OR</p>
            <div className="row">
              <div className="col-md-6">
                <label><strong>Select a Mood:</strong></label>
                <div className="table-responsive">
                  <table className="table table-bordered mood-tone-table">
                    <tbody>
                      <tr className={`mood-row ${selectedMood === 'Neutral' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedMood === 'Neutral' ? 'selected' : ''}`} onClick={() => handleMoodChange('Neutral')}><FontAwesomeIcon icon={faPeace} /> Neutral</button></td></tr>
                      <tr className={`mood-row ${selectedMood === 'Happy' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedMood === 'Happy' ? 'selected' : ''}`} onClick={() => handleMoodChange('Happy')}><FontAwesomeIcon icon={faSmile} /> Happy</button></td></tr>
                      <tr className={`mood-row ${selectedMood === 'Sad' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedMood === 'Sad' ? 'selected' : ''}`} onClick={() => handleMoodChange('Sad')}><FontAwesomeIcon icon={faFrown} /> Sad</button></td></tr>
                      <tr className={`mood-row ${selectedMood === 'Angry' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedMood === 'Angry' ? 'selected' : ''}`} onClick={() => handleMoodChange('Angry')}><FontAwesomeIcon icon={faAngry} /> Angry</button></td></tr>
                      <tr className={`mood-row ${selectedMood === 'Excited' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedMood === 'Excited' ? 'selected' : ''}`} onClick={() => handleMoodChange('Excited')}><FontAwesomeIcon icon={faLaugh} /> Excited</button></td></tr>
                      <tr className={`mood-row ${selectedMood === 'Calm' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedMood === 'Calm' ? 'selected' : ''}`} onClick={() => handleMoodChange('Calm')}><FontAwesomeIcon icon={faPeace} /> Calm</button></td></tr>
                      <tr className={`mood-row ${selectedMood === 'Concerned' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedMood === 'Concerned' ? 'selected' : ''}`} onClick={() => handleMoodChange('Concerned')}><FontAwesomeIcon icon={faHandshake} /> Concerned</button></td></tr>
                    </tbody>
                    
                  </table>
                </div>
                <div className="form-group mt-2">
           
                  <input type="text" className="form-control" placeholder="Custom Mood" value={customMood} onChange={handleCustomMoodChange} />
                </div>
              </div>
              <div className="col-md-6">
                <label><strong>Select a Tone:</strong></label>
                <div className="table-responsive">
                  <table className="table table-bordered mood-tone-table">
                    <tbody>
                      <tr className={`tone-row ${selectedTone === 'Formal' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedTone === 'Formal' ? 'selected' : ''}`} onClick={() => handleToneChange('Formal')}><FontAwesomeIcon icon={faUserTie} /> Formal</button></td></tr>
                      <tr className={`tone-row ${selectedTone === 'Informal' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedTone === 'Informal' ? 'selected' : ''}`} onClick={() => handleToneChange('Informal')}><FontAwesomeIcon icon={faUserFriends} /> Informal</button></td></tr>
                      <tr className={`tone-row ${selectedTone === 'Friendly' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedTone === 'Friendly' ? 'selected' : ''}`} onClick={() => handleToneChange('Friendly')}><FontAwesomeIcon icon={faGrin} /> Friendly</button></td></tr>
                      <tr className={`tone-row ${selectedTone === 'Serious' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedTone === 'Serious' ? 'selected' : ''}`} onClick={() => handleToneChange('Serious')}><FontAwesomeIcon icon={faUserNurse} /> Serious</button></td></tr>
                      <tr className={`tone-row ${selectedTone === 'Humorous' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedTone === 'Humorous' ? 'selected' : ''}`} onClick={() => handleToneChange('Humorous')}><FontAwesomeIcon icon={faLaugh} /> Humorous</button></td></tr>
                      <tr className={`tone-row ${selectedTone === 'Respectful' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedTone === 'Respectful' ? 'selected' : ''}`} onClick={() => handleToneChange('Respectful')}><FontAwesomeIcon icon={faHandshake} /> Respectful</button></td></tr>
                      <tr className={`tone-row ${selectedTone === 'Assertive' ? 'selected' : ''}`}><td><button className={`btn w-100 ${selectedTone === 'Assertive' ? 'selected' : ''}`} onClick={() => handleToneChange('Assertive')}><FontAwesomeIcon icon={faThumbsUp} /> Assertive</button></td></tr>
                    </tbody>
                  </table>
                </div>
                <div className="form-group mt-2">
         
                  <input type="text" className="form-control" placeholder="Custom Tone" value={customTone} onChange={handleCustomToneChange} />
                </div>
              </div>
            </div>
            <button className="btn btn-primary mt-3 get-in-touch" type="button" onClick={() => { displayCustomEnhancedText(); setShowMoodToneModal(false); }}>Enhance with chosen Mood and Tone</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoodToneAssistant;
