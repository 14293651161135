import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/home.css'; // Import your CSS file for additional styles
import { auth } from '../firebase-config'; // Ensure path matches your file structure
import { signOut } from 'firebase/auth';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = async (event) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      setIsMenuOpen(false); // Close the menu when logging out
      await signOut(auth);
      navigate("/");
      scrollToTop();
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <div className={`site-mobile-menu site-navbar-target ${isMenuOpen ? 'open' : ''}`}>
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle" onClick={toggleMenu}></span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>

      <header className={`site-navbar py-4 js-sticky-header site-navbar-target ${isScrolled ? 'scrolled' : ''}`} role="banner">
        <div className="container">
          <div className=" align-items-center">
            <div className="col-11 col-xl-2">  
            <Link to="/" className="text-black mb-0" onClick={scrollToTop}>
              <h1 className="mb-0 site-logo">
                
                  <img src="/images/img_51.png" alt="Small plane" style={{ width: '100px', height: 'auto', marginLeft: '10px', float: "left" }} />
              
              </h1>
              </Link>
            </div>
            <div className="col-12 col-md-10 d-xl-block">
              <nav className="site-navigation position-relative text-right" role="navigation">
                <ul style={{ marginLeft: '150px' }} className={`site-menu main-menu js-clone-nav mr-auto ${isMenuOpen ? 'show' : ''}`}> 
                  <li>
                    <Link to={auth.currentUser ? "/logged-in" : "/"} className="nav-link" onClick={scrollToTop}>Home</Link>
                  </li>
                  <li className="ml-3 ml-md-5"><Link to="/about" className="nav-link" onClick={scrollToTop}>About</Link></li>
                  <li className="ml-3 ml-md-5"><Link to="/join" className="nav-link" onClick={scrollToTop}>Join</Link></li>
                  <li className="ml-3 ml-md-5"><Link to="/pricing" className="nav-link" onClick={scrollToTop}>Pricing</Link></li>
                  {auth.currentUser ? (
                    <li className="ml-3 ml-md-5"><Link to="/" onClick={handleLogout} className="nav-link">Log out</Link></li>
                  ) : (
                    <li className="ml-3 ml-md-5"><Link to="/login" className="nav-link" onClick={scrollToTop}>Login</Link></li>
                  )}
                </ul>
              </nav>
            </div>
            <div className="d-inline-block d-xl-none ml-md-0 mr-auto py-3" style={{ position: 'relative', top: '3px' }}>
              <span className="icon-menu h3" onClick={toggleMenu}></span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
