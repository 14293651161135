import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const TemplateUpdateModal = ({
  showTemplateModal,
  setShowTemplateModal,
  selectedTemplate,
  setSelectedTemplate,
  handleTemplateUpdate,
  loading,
}) => {
  const templates = ['Coral', 'Serif', 'Swiss', 'Modern Writer', 'Spearmint'];

  return (
    <div
      className={`modal fade ${showTemplateModal ? 'show' : ''}`}
      tabIndex="-1"
      role="dialog"
      style={{ display: showTemplateModal ? 'block' : 'none' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Resume Template</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setShowTemplateModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="templateSelect">Select Resume Template:</label>
              <select
                className="form-control"
                id="templateSelect"
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                {templates.map((template) => (
                  <option key={template} value={template}>
                    {template}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              onClick={() => {
                handleTemplateUpdate();
                setShowTemplateModal(false);
              }}
              disabled={loading}
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Update Template'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateUpdateModal;
