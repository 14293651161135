import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ReactCountryFlag from "react-country-flag";

function Pricing() {
    const [popupVisible, setPopupVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('plans');

    const plans = [
        {
            id: 1,
            name: 'Free Account',
            price: 'FREE',
            features: [ '10 Free U-Mails a month', 'Access to the Intergrated U-mail platform', 'Limited features', 'With Ad\'s'],
            delay: '0.1s'
        },
        {
            id: 2,
            startingAt: 'Starting At',
            name: 'Single Account',
            price: '$12.99 *',
            period: 'Monthly',
            features: [ '24/7 support', 'Enhanced features', 'Ad-free', '* Take a look at our price matrix for more details!'],
            delay: '0.1s',
            label: 'Best!'
        },
        {
            id: 3,
            startingAt: 'Starting At',
            name: 'Business Account',
            price: '$59.99 *',
            period: 'Monthly',
            features: [ '24/7 support', 'Enhanced features', 'Ad-free', '* Please contact us for more details'],
            delay: '0.1s'
        }
    ];

    const handleLinkClick = (e) => {
        e.preventDefault();
        setPopupVisible(true);
    };

    useEffect(() => {
        if (popupVisible) {
            const timer = setTimeout(() => {
                setPopupVisible(false);
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [popupVisible]);

    const languages = [
        { name: 'English', code: 'en', flagCode: 'GB', key: 'en-GB' },
        { name: 'Spanish', code: 'es', flagCode: 'ES', key: 'es-ES' },
        { name: 'French', code: 'fr', flagCode: 'FR', key: 'fr-FR' },
        { name: 'Italian', code: 'it', flagCode: 'IT', key: 'it-IT' },
        { name: 'Latin Spanish', code: 'es', flagCode: 'MX', key: 'es-MX' },
        { name: 'Greek', code: 'el', flagCode: 'GR', key: 'el-GR' },
        { name: 'German', code: 'de', flagCode: 'DE', key: 'de-DE' },
        { name: 'Punjabi', code: 'pa', flagCode: 'IN', key: 'pa-IN' },
        { name: 'Mandarin Chinese', code: 'zh', flagCode: 'CN', key: 'zh-CN' },
        { name: 'Cantonese Chinese', code: 'zh', flagCode: 'HK', key: 'zh-HK' },
        { name: 'Japanese', code: 'ja', flagCode: 'JP', key: 'ja-JP' },
        { name: 'Hindi', code: 'hi', flagCode: 'IN', key: 'hi-IN' },
        { name: 'Russian', code: 'ru', flagCode: 'RU', key: 'ru-RU' },
        { name: 'Portuguese', code: 'pt', flagCode: 'PT', key: 'pt-PT' },
        { name: 'Arabic', code: 'ar', flagCode: 'SA', key: 'ar-SA' },
        { name: 'Hebrew', code: 'he', flagCode: 'IL', key: 'he-IL' },
        { name: 'Contact us to add yours!', code: '', flagCode: '', key: '' },
        { name: 'Aussie Slang', code: 'en', flagCode: 'AU', key: 'en-AU' },
        { name: 'Cockney British', code: 'en', flagCode: 'GB', key: 'en-GB-2' },
        { name: 'More coming for', code: '', flagCode: '', key: '' },
    ];

    const LanguageDropdown = () => {
        return (
            <DropdownButton id="languageDropdown" title="Available Languages" variant="">
                {languages.slice(0, 17).map((language) => (
                    <Dropdown.Item key={language.key} href="#">
                        {language.flagCode && (
                            <ReactCountryFlag 
                                countryCode={language.flagCode} 
                                svg 
                                style={{ marginRight: '10px' }}
                            />
                        )}
                        {language.name}
                    </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                {languages.slice(17, 20).map((language) => (
                    <Dropdown.Item key={language.key} href="#">
                        <ReactCountryFlag 
                            countryCode={language.flagCode} 
                            svg 
                            style={{ marginRight: '10px' }}
                        />
                        {language.name} <span style={{ color: 'green', fontWeight: 'bold' }}>(Free)</span>
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        );
    };
    

    return (
        <section id="pricing" className="pricing-content section-padding">
            <div className="container">
            <div className="custom-container">
  <div className="custom-section-title text-center">
    <h1 className="custom-title">Pricing Plan</h1>
    <p className="custom-description">
      <strong>
        Upgrade to a U-Mail paid plan and elevate your communication game, offering a personalized and cost-effective experience. Enjoy enhanced security, and receive dedicated support tailored to your needs. Explore our pricing and feature plan tabs to uncover the full range of benefits. Take control, streamline your communication, and experience the unparalleled efficiency of U-Mail. Upgrade today and make every message count!
      </strong>
    </p>
  </div>
  {popupVisible && (
    <div className="custom-alert" role="alert">
      <strong>We are currently in the alpha testing phase of our product. Stay tuned and join our waitlist for a chance to participate in the upcoming beta testing!</strong>
    </div>
  )}
</div>

                <div className="tabs-section">
                    <ul className="nav nav-tabs custom-tabs">
                        <li className="nav-item">
                            <a className={`nav-link ${activeTab === 'plans' ? 'active' : ''}`} href="#" onClick={(e) => { e.preventDefault(); setActiveTab('plans'); }}>Plans</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${activeTab === 'features' ? 'active' : ''}`} href="#" onClick={(e) => { e.preventDefault(); setActiveTab('features'); }}>Features</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${activeTab === 'pricingMatrix' ? 'active' : ''}`} href="#" onClick={(e) => { e.preventDefault(); setActiveTab('pricingMatrix'); }}>Pricing Matrix</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        {activeTab === 'plans' && (
                            <div className="tab-pane active" id="plans">
                                <div className="row text-center">
                                    {plans.map((plan) => (
                                        <div key={plan.id} className={`col-lg-4 col-sm-4 col-xs-12 wow fadeInUp`} data-wow-duration="1s" data-wow-delay={plan.delay} data-wow-offset="0">
                                            <div className={`single-pricing ${plan.label ? 'single-pricing-white' : ''}`}>
                                                <div className="price-head">
                                                    <h2>{plan.name}</h2>
                                                    {Array.from({ length: 6 }).map((_, index) => <span key={index}></span>)}
                                                </div>
                                                {plan.label && <span className="price-label">{plan.label}</span>}
                                                <h5>{plan.startingAt}</h5>
                                                <h1 className="price">{plan.price}</h1>
                                                <h5>{plan.period}</h5>
                                                <ul>
                                                    {plan.features.map((feature, index) => <li key={index}>{feature}</li>)}
                                                </ul>
                                                <a href="#" onClick={handleLinkClick}>Coming soon!</a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {activeTab === 'features' && (
                            <div className="tab-pane active" id="features">
                                <div className="features-table">
                                    <table className="table table-bordered custom-table">
                                        <thead>
                                            <tr>
                                                <th>Features</th>
                                                <th>Free Plan</th>
                                                <th>All Paid Plans</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='tableTitle' colSpan="3"><strong>Account Features</strong></td>
                                            </tr>
                                            <tr>
                                                <td>One account</td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                           
                                            <tr>
                                                <td>10 Free U-Mails a month</td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                                <td> <FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited U-Mails for each paid account.</td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td className='tableTitle' colSpan="3"><strong>Support & Security</strong></td>
                                            </tr>
                                            <tr>
                                                <td>24/7 support</td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Enhanced security</td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td className='tableTitle' colSpan="3"><strong>Ad & Branding</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Ad-free</td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                         
                                            <tr>
                                                <td className='tableTitle' colSpan="3"><strong>AI Features</strong></td>
                                            </tr>
                                            <tr>
                                                <td>AI-assisted punctuation, grammar, and spelling corrections</td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>


                                            <tr>
                                                <td>Translate to any language in seconds!   <LanguageDropdown /></td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Voice-to-text functionality</td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Customizable email tones and moods</td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Dictate emails on the go</td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>AI-assisted email creation</td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Integration of multiple messaging apps</td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Custom AI Synonym assistance</td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Ghosting feature for unwanted contacts </td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Customizable automated responses</td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <td>Summarized interaction reports (daily, weekly, monthly) <u><i>Coming soon!</i></u></td>
                                                <td></td>
                                                <td><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                        {activeTab === 'pricingMatrix' && (
                            <div className="tab-pane active" id="pricingMatrix">
                                <div className="pricing-matrix">
                                    <h2 className="text-center">U-Mail Pricing</h2>
                                    
                                    <table className="table table-bordered custom-table">
                                        <thead>
                                            <tr>
                                                <th>Feature</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td className='tableTitle' ><strong>Standard Access</strong></td>
                                        
                                                <td className='priceTD'>$12.99/month</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">Stardard features include, One dedicated Account, Profensional messages, Custom Moods & tones, Dictate emails on the go,
                                                     Ghosting Feature, Customizable automated responses & Synonym, Spelling and Grammar assistants, Access to industry specific tools, language translations
                                                   <LanguageDropdown /></td> 

                                            </tr>
                                            <tr>
                                                <td className='tableTitle'>Additional Email, Phone or Social Media Account</td>
                                                <td className='priceTD'>$5.99/month each</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">Add extra email, phone, or social media accounts to your plan. Enjoy free integration within your monthly usage limit, sending & receiving messages</td>
                                            </tr>
                                       
                                    


                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Pricing;
