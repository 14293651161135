import React from 'react';

const FAQ = () => {
  return (
    <div className="container mt-5">
      <div className="card shadow-lg">
        <div className="card-header text-white">
          <h2 className="mb-0">Frequently Asked Questions (FAQs)</h2>
        </div>
        <div className="card-body">
          <div className="accordion" id="faqAccordion">
            {/* Each FAQ is wrapped in a Bootstrap accordion item */}
            
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  How do I create a new U-Mail?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  To create a new U-Mail, click on the "Compose" button in the sidebar. Enter the recipient's email address, subject, and your message. You can use AI to enhance your message by clicking the "Enhance with AI" button before sending the email. You can also schedule your email to be sent later by using the "Schedule Send" option.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  How do I use the voice-to-text feature?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  Click on the microphone icon in the compose window to start the voice-to-text feature. Speak clearly, and your message will be transcribed in real-time. Click the microphone icon again to stop recording. You can also enhance the transcribed text with AI to improve its quality and tone.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  What is the AI enhancement feature?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  The AI enhancement feature helps you improve your email by suggesting better wording, creating lists or bullet points, and adjusting the tone to match your desired style. Whether you want to make your message more professional, empathetic, or energetic, the AI will refine your text based on your chosen preferences.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  How do I use the AI enhancement feature?
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  After typing your message, click on the "Enhance with AI" button. The AI will process the text and suggest improvements. You can accept the changes or edit them further. If needed, you can also choose a specific tone or style for the enhancement, like formal, creative, or empathetic.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Is there a limit to how many times I can enhance my email?
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  No, there is no limit to how many times you can enhance your email. However, we recommend limiting enhancements to ensure your message remains concise and clear. You can enhance the same email multiple times until you're satisfied with the content.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  What happens to the original text after enhancement?
                </button>
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  The AI enhancement will modify your text in real-time, but you can always undo the changes or manually adjust the enhanced content. The original text is overwritten in the message box, but you can choose to revert to your initial draft if needed.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSeven">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                  Can the AI create lists and bullet points?
                </button>
              </h2>
              <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  Yes, the AI can create lists and bullet points based on your input. You can either manually request bullet points in your original draft or let the AI automatically format lists during the enhancement process for better readability.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEight">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                  Does the AI remove inappropriate language?
                </button>
              </h2>
              <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  Yes, the AI automatically detects and removes any inappropriate language, ensuring that your email is professional and suitable for all audiences. The AI will replace inappropriate terms with more appropriate alternatives.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwelve">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                  How do I generate a cover letter tailored to a job description?
                </button>
              </h2>
              <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  To generate a tailored cover letter, go to the "Job Help" section in the sidebar. Click "Cover Letter Assistant" and paste the job description. U-Mail will highlight key points from the job description and generate a personalized cover letter to match the employer’s needs.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThirteen">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                  How do I add a Google Meet link to a calendar event?
                </button>
              </h2>
              <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  When creating or editing a calendar event, click the "Add Google Meet" button. U-Mail will generate a Google Meet link and include it in the event invitation, making it easy for participants to join the meeting.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFourteen">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                  How do I customize the mood and tone of my emails?
                </button>
              </h2>
              <div id="collapseFourteen" className="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#faqAccordion">
                <div className="accordion-body">
                  After writing or talking your message, click on the "Set Mood/Tone" option. You can select from different options like Empathetic, Professional, Creative, or Energetic. The AI will adjust the tone of your message accordingly before sending it.
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
