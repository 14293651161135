import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const industries = [
  'Medical',
  'Legal',
  'Finance',
  'Technology',
  'Education',
];

const IndustryAssistant = ({ handleIndustryChange, showIndustryModal, setShowIndustryModal, loading }) => {
  return (
    <>
      {showIndustryModal && (
        <div className={`modal fade show`} tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Select Industry</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowIndustryModal(false)}></button>
              </div>
              <div className="modal-body">
                <div className="list-group">
                  {industries.map(industry => (
                    <button type="button" className="list-group-item list-group-item-action" key={industry} onClick={() => handleIndustryChange(industry)}>
                      {industry}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Loading Spinner */}
      {loading && (
        <div className="spinner-overlay">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      )}
    </>
  );
};

export default IndustryAssistant;
