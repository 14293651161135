import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider } from '../firebase-config';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';

const Login = ({ setAccessToken }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [popup, setPopup] = useState('');
  const navigate = useNavigate();

  const authorizedEmails = ['shanemiller500@gmail.com', 'jaredpollack3572@gmail.com', 'michael.t.gaughan@gmail.com'];

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!authorizedEmails.includes(email)) {
      setPopup('Access restricted. Permissions will be granted in due time.');
      return;
    }
    try {
      // This is where we get the auth token from firebase
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('userCredential',userCredential)
      const user = userCredential.user;
      console.log('user', user)
      const token = await user.getIdToken(); // Get the token
      console.log('token', token)
      setAccessToken(token); // Pass the token to the parent component
      navigate('/logged-in');
    } catch (error) {
      setPopup('Incorrect email or password');
      setTimeout(() => setPopup(''), 3000);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken(); // Get the token
      setAccessToken(token); // Pass the token to the parent component
      navigate('/logged-in');
    } catch (error) {
      setPopup('Google sign-in failed');
      setTimeout(() => setPopup(''), 3000);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card marginTop">
            <div className="card-body">
              <h1 className="card-title text-center">Login</h1>
              {popup && <div className="alert alert-danger">{popup}</div>}
              <form onSubmit={handleLogin} className="mt-4">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </div>
              </form>
              <div className="text-center mt-3">
                {/* <button className="btn btn-google-signin" onClick={handleGoogleSignIn}>
                  <img
                    src="https://developers.google.com/identity/images/g-logo.png"
                    alt="Google Sign-In"
                    className="google-logo"
                  />
                  Google Login
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
