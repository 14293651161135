// speechUtils.js

export function replaceYouMail(text) {
    return text.replace(/\byou mail\b/gi, 'U-Mail')
               .replace(/\bYou Mail\b/gi, 'U-Mail')
               .replace(/\byoumail\b/gi, 'U-Mail');
}

export function processSpecialCommands(words) {
    words = replaceYouMail(words.join(' ')).split(' '); // Replace "you mail" with "U-Mail" first
    const specialCommands = {
        'comma': ',',
        'enter': '\n\n',
        'period': '.',
        'dash': '-',
        'exclamation': '!',
        'paragraph': '\n\n\n\n',
        'space': ' ',
        'tab': '\t',
        'semicolon': ';',
        'colon': ':',
        'question': '?',
        'slash': '/',
        'backslash': '\\',
        'quote': '"',
        'apostrophe': '\'',
        'equals': '=',
        'plus': '+',
        'asterisk': '*',
        'ampersand': '&',
        'caret': '^',
        'percent': '%',
        'dollar': '$',
        'pound': '#',
        'tilde': '~',
        'pipe': '|',
        'less': '<',
        'greater': '>',
        'braces': '{}',
        'brackets': '[]',
        'parentheses': '()',
        'underscore': '_'
    };

    return words.map(word => {
        const command = specialCommands[word.toLowerCase()];
        return command ? command : word; 
    }).join(' ');
}

export function updateFormFields(transcript, fieldKeywords) {
    transcript = replaceYouMail(transcript); // Replace "you mail" with "U-Mail" first
    const words = transcript.toLowerCase().split(' ');
    let currentField = null;
    let fieldValues = Object.keys(fieldKeywords).reduce((acc, field) => {
        acc[field] = '';
        return acc;
    }, {});
    let buffer = [];

    words.forEach(word => {
        if (currentField) {
            if (Object.values(fieldKeywords).flat().includes(word)) {
                fieldValues[currentField] = buffer.join(' ');
                buffer = [];
                currentField = Object.keys(fieldKeywords).find(key => fieldKeywords[key].includes(word));
            } else {
                buffer.push(word);
            }
        } else {
            if (Object.values(fieldKeywords).flat().includes(word)) {
                currentField = Object.keys(fieldKeywords).find(key => fieldKeywords[key].includes(word));
            }
        }
    });

    if (currentField) {
        fieldValues[currentField] = buffer.join(' ');
    }

    return fieldValues;
}

export function handleSpeechCommands(command, actions) {
    const normalizedCommand = replaceYouMail(command.toLowerCase().trim()); 
    switch (normalizedCommand) {
        case 'start speaking':
            actions.startListening();
            break;
        case 'stop recording':
            actions.stopListening();
            break;
        case 'save to draft':
            actions.saveDraft();
            break;
        default:
            break;
    }
}
