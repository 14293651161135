import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion } from 'react-bootstrap';

const FAQ = () => {
  return (
    <div className="faq-container container mt-4">
      <h1 className="faq-title mb-4 text-center">FAQs</h1>
      <div className="row">
        <div className="col-md-6">
          <Accordion className="faq-accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is U-Mail?</Accordion.Header>
              <Accordion.Body className="lead">
                U-Mail is a groundbreaking email application designed to elevate your email communication. Seamlessly integrating with Gmail, it offers advanced AI-powered text enhancement, voice-to-text capabilities, and customizable mood and tone settings. Whether you're at your desk or on the go, U-Mail ensures your emails are always professional, polished, and effective.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Why should I subscribe to U-Mail?</Accordion.Header>
              <Accordion.Body className="lead">
                Subscribing to U-Mail unlocks a suite of advanced features that transform your email management. Our AI-driven tools streamline your workflow, saving you hours each week and allowing you to focus on what truly matters. U-Mail ensures your messages are clear, concise, and impactful, giving you a competitive edge in your professional communication. Experience unparalleled productivity and efficiency with U-Mail.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>How am I fully in control of the text of my email?</Accordion.Header>
              <Accordion.Body className="lead">
                U-Mail puts you in full control of your email content. You can edit your emails before and after applying our AI enhancements, ensuring your original intent is preserved while benefiting from professional polish. This balance of automation and personal touch ensures your emails are both effective and authentic.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Do you store email data at U-Mail?</Accordion.Header>
              <Accordion.Body className="lead">
                No, U-Mail does not store your email data. Your emails remain securely stored with your email provider. U-Mail accesses your emails with your permission to provide our services, ensuring your privacy and security are always maintained.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>How can U-Mail help me stand out in my professional communication?</Accordion.Header>
              <Accordion.Body className="lead">
                U-Mail's advanced AI capabilities ensure your emails are not only grammatically correct but also engaging and persuasive. By enhancing your messages with clear, impactful language, U-Mail helps you make a strong impression in your professional interactions, giving you a competitive edge in business communication.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>How does U-Mail ensure the security of my emails?</Accordion.Header>
              <Accordion.Body className="lead">
                Security is a top priority for U-Mail. We use state-of-the-art encryption and security protocols to protect your data. Our integration with Gmail leverages Google's robust security measures, ensuring your information remains safe and secure at all times.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="14">
              <Accordion.Header>How can U-Mail help me manage emails on the go?</Accordion.Header>
              <Accordion.Body className="lead">
                U-Mail is designed for mobility. With features like voice-to-text, you can dictate emails on the go, save drafts, and refine them later. This flexibility allows you to handle your communications efficiently, whether you're commuting, traveling, or multitasking, giving you more time for yourself and your family.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="15">
              <Accordion.Header>Can U-Mail help improve my productivity?</Accordion.Header>
              <Accordion.Body className="lead">
                Absolutely! U-Mail is built to enhance your productivity by streamlining email management. AI text enhancements, voice-to-text functionality, and easy access to your Gmail inbox help you focus on what matters most without being bogged down by email tasks. Compose, edit, and send emails quickly and effectively with U-Mail.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
  <Accordion.Header>Are there mobile apps and Chrome extensions for U-Mail?</Accordion.Header>
  <Accordion.Body className="lead">
    We are excited to announce that U-Mail will soon have dedicated mobile apps for both Android and iOS devices, as well as a Chrome extension! Our development team is currently building these features to enhance your email management experience. With the upcoming Android and iOS apps, you'll be able to manage your emails on the go, ensuring you stay connected and productive no matter where you are. The Chrome extension will seamlessly integrate U-Mail's powerful features into your browser, making it even easier to compose, send, and organize your emails with the right mood and tone. Stay tuned for updates on their release!
  </Accordion.Body>
</Accordion.Item>

          </Accordion>
          

        </div>
        <div className="col-md-6">
          <Accordion className="faq-accordion">
            <Accordion.Item eventKey="6">
              <Accordion.Header>Can U-Mail help me manage my time better?</Accordion.Header>
              <Accordion.Body className="lead">
                Yes! U-Mail's AI-driven features streamline your email workflow, saving you valuable time. Whether drafting quick responses or enhancing long emails, U-Mail helps you handle communications efficiently, freeing up time for other important tasks and boosting overall productivity.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>What makes U-Mail's AI mood and tone customization unique?</Accordion.Header>
              <Accordion.Body className="lead">
                U-Mail's AI allows you to customize the mood and tone of your emails to suit your needs. Whether you need a formal, friendly, or humorous tone, our AI crafts the perfect message. It can also reframe harsh words into a creative, positive email, ensuring your communication is always appropriate and effective.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>How can U-Mail's AI improve my email campaigns?</Accordion.Header>
              <Accordion.Body className="lead">
                U-Mail's AI can significantly enhance your email campaigns by crafting compelling and engaging content. From newsletters to promotional emails, U-Mail helps you create high-quality emails that resonate with your audience, increasing open rates and engagement.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>What makes U-Mail's AI different from other email tools?</Accordion.Header>
              <Accordion.Body className="lead">
                U-Mail's AI stands out due to its ability to understand context and tone, ensuring your emails are not just error-free but also emotionally intelligent. Whether writing a casual message or a formal business proposal, U-Mail's AI makes your communication more effective and impactful.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="10">
              <Accordion.Header>Say what you really want to say with U-Mail!</Accordion.Header>
              <Accordion.Body className="lead">
              Ever find yourself wanting to say exactly how you feel but knowing it's not professional? With U-Mail, you can speak or type out your true thoughts, no matter how blunt, and use our AI enhancer to reword it into a polished, professional email with all the harshness taken out. Let U-Mail help you communicate effectively while still staying true to your feelings. Be you with U-Mail, or choose our standard professional approach for your emails.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="11">
              <Accordion.Header>How much time can I save with U-Mail?</Accordion.Header>
              <Accordion.Body className="lead">
                U-Mail is designed to save you significant time in managing emails. With AI-powered text enhancements and voice-to-text features, you can dictate messages on the go, save them, and fine-tune them later. Handle communications efficiently, giving you more time for yourself and your family.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="12">
              <Accordion.Header>Can U-Mail help me with writing introduction emails?</Accordion.Header>
              <Accordion.Body className="lead">
                Absolutely! U-Mail's AI can generate sharp, professional introduction emails with minimal input. Ensure your introduction emails are engaging and leave a great first impression, whether introducing yourself to a new contact or reaching out to potential clients.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="13">
              <Accordion.Header>Why is U-Mail the best option for anyone in any industry?</Accordion.Header>
              <Accordion.Body className="lead">
                U-Mail offers unparalleled convenience and efficiency, making it ideal for professionals across all industries. Seamless integration with Gmail and robust AI enhancement features ensure your emails are professional and polished. U-Mail's security measures keep your data safe, providing peace of mind while you work.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="16">
              <Accordion.Header>Why not just use ChatGPT?</Accordion.Header>
              <Accordion.Body className="lead">
                While ChatGPT is a powerful tool, U-Mail is specifically designed for email communication. U-Mail's AI models are tailored to enhance email messages with the right mood and tone, making your emails more effective and impactful. Plus, U-Mail allows for easy editing and fine-tuning, ensuring you get the perfect message every time. Experience unmatched efficiency and precision with U-Mail, designed to make your email management faster and more intuitive.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
    
  );
};

export default FAQ;
