import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faUserSecret, faTrashAlt, faEnvelope, faRobot } from '@fortawesome/free-solid-svg-icons';

const PrivacyPage = () => {
  return (
    <div className="container privacy-container">
      <h1 className="privacy-title text-center">Privacy Policy</h1>
      <p className="privacy-text text-center">
        <strong>Last Updated: 6/15/2024</strong>
      </p>

      <div className="row">
  <div className="col-md-12 privacy-section">
    <div className="privacy-card">
      <h2 className="privacy-section-title text-center">Your Rights</h2>
      <p className="privacy-text text-center">
        At U-Mail, we are committed to upholding your rights to privacy and data protection. You have the right to access, correct, update, or delete your personal information at any time. If you wish to exercise any of these rights, please reach out to our dedicated support team if you cant access the details in the settings tab when logged-in.
      </p>
      <p className="privacy-text text-center">
        We understand the importance of your personal data and are devoted to handling it with the utmost care and respect. Our team is dedicated to ensuring that your data is managed securely and in compliance with all applicable privacy laws and regulations.
      </p>
      <p className="privacy-text text-center">
        Your trust is invaluable to us, and we strive to provide a transparent and secure environment for your communications. If you have any questions, concerns, or need assistance with your data, please do not hesitate to contact us.
      </p>
      {/* <p className="privacy-text text-center">
        <strong>Contact Us:</strong> If you have any questions or concerns about our privacy practices, please contact us. We are here to help and ensure that your privacy rights are respected and protected.
      </p> */}
    </div>
  </div>
</div>


<div className="row">

<div className="col-md-6 privacy-section">
  <div className="privacy-card">
    <FontAwesomeIcon icon={faUserSecret} className="privacy-icon" />
    <h2 className="privacy-section-title">Data Storage</h2>
    <p className="privacy-text">
      At U-Mail, we take the security of your personal data very seriously. Your information is stored securely and protected against unauthorized access, ensuring that your data remains confidential and intact.
    </p>
    <p className="privacy-text">
      We employ industry-standard security measures and secure servers, to safeguard your data from potential threats and vulnerabilities. Our security protocols are continuously updated to adapt to the evolving digital landscape, providing you with the highest level of protection.
    </p>
    <p className="privacy-text">
      In addition to these technical measures, we also enforce strict access controls and protocols to ensure that only authorized U-Mail personnel can access your data if necessary. This comprehensive approach ensures that your personal information is always handled with the utmost care and security.
    </p>
    <p className="privacy-text">
      Your trust is paramount to us, and we are dedicated to maintaining the security and integrity of your personal data at all times. With U-Mail, you can have peace of mind knowing that your information is protected by robust and state-of-the-art security measures.
    </p>
  </div>
</div>



  <div className="col-md-6 privacy-section">
  <div className="privacy-card">
    <FontAwesomeIcon icon={faEnvelope} className="privacy-icon" />
    <h2 className="privacy-section-title">Account Management</h2>
    <p className="privacy-text">
      At U-Mail, we believe in empowering you with complete control over your personal data. You have the unequivocal right to access, modify, or delete your personal information at any time.
    </p>
    <p className="privacy-text">
      Our user-friendly settings tab allows you to manage your account effortlessly. Whether you wish to update your information or remove your account entirely, we provide the tools for you to do so quickly and securely. We do not hold your data hostage for days or weeks, if you want it gone it will be the moment you request.
    </p>
    <p className="privacy-text">
      By choosing to remove your account, all associated data will be permanently deleted from our systems, ensuring that your personal information is entirely erased. This gives you absolute control and peace of mind regarding your privacy.
    </p>
    <p className="privacy-text">
      We are committed to making sure that you have the power to manage your data in a way that suits your needs. Your privacy and control over your personal information are paramount to us.
    </p>
  </div>
</div>




  <div className="col-md-6 privacy-section">
    <div className="privacy-card">
      <FontAwesomeIcon icon={faShieldAlt} className="privacy-icon" />
      <h2 className="privacy-section-title">Data Collection and Use</h2>
      <p className="privacy-text">
        At U-Mail, we are committed to collecting only the minimal amount of personal data necessary to provide you with our exceptional services. The information we gather includes essential details such as your email address, login credentials, the details you added when you registered, and your U-Mail Addon information. This data is crucial for the seamless operation of our application and to ensure you have the best user experience possible.
      </p>
      <p className="privacy-text">
        Our primary goal is to facilitate your use of U-Mail in a secure and efficient manner. We use your personal information solely to enhance our services and to ensure that our application functions smoothly. Rest assured, we handle your data with the highest level of confidentiality and security.
      </p>
      <p className="privacy-text">
        We believe in transparency and accountability when it comes to your personal information. By using U-Mail, you can trust that your data is being used responsibly and ethically to improve our services and to better serve you.
      </p>
      <p className="privacy-text">
      The only data we'll share, which does not directly identify an individual user, includes:
        <ul>
          <li>Approximate location: General region based on IP address to provide localized services.</li>
          <li>Age range: Non-specific age bracket (e.g., 20-30 years) for demographic analysis.</li>
          <li>Industry: General industry category for better service customization.</li>
          <li>Gender: General gender information for demographic purposes.</li>
          <li>Browser and device information: Type of browser, operating system, and device for technical support and optimization.</li>
          <li>Aggregate data: Statistical data collected in aggregate form to improve overall service performance.</li>
          <li>Usage data: Information on how users interact with the service, such as pages visited and time spent on each page.</li>
          <li>Technical data: Data like IP addresses and cookies that help in maintaining security and enhancing user experience.</li>
          <li>Demographic data: General data about groups of users that do not identify any individual user.</li>
        </ul>
        No other data will be available at all. No third-party collaboration is worth compromising your privacy. Your trust is our highest priority, and we are dedicated to protecting it.
      </p>
    </div>
  </div>





<div className="col-md-6 privacy-section">
  <div className="privacy-card">
    <FontAwesomeIcon icon={faRobot} className="privacy-icon" />
    <h2 className="privacy-section-title">AI Data Handling and Data Sharing</h2>
    <p className="privacy-text">
      At U-Mail, we understand the critical role that AI plays in enhancing our services. However, we prioritize your privacy and the confidentiality of your personal information above all else. We recognize the importance of keeping your personal messages and data confidential, and we are committed to ensuring that your data remains private and secure.
    </p>
    <p className="privacy-text">
      Any data inputted for AI purposes is processed in a secure and isolated local environment. We ensure that your personal data read for enhancement is anonymized and aggregated to maintain your privacy. This means that individual pieces of data pre and post enhancement are never linked back to you personally and are completely erased from any system after enhancement. All sent messages are stored on the respective platform you chose to send them on, such as Gmail or your phone provider for text messages.
    </p>
    <p className="privacy-text">
      Importantly, we have made a firm decision to opt out of sharing your information with OpenAI and other third-party entities for machine learning purposes. We believe that no amount of potential benefit justifies compromising your privacy.
    </p>
    <p className="privacy-text">
      We are committed to using AI ethically and responsibly. Our AI models are designed to improve your experience without compromising your privacy. We do not share AI-processed data with third parties, and we continuously monitor our AI systems to ensure compliance with the highest privacy standards.
    </p>
    <p className="privacy-text">
      By choosing U-Mail, you can be confident that your data is handled with the utmost care and integrity. Your trust is our highest priority, and we are dedicated to protecting it. Our commitment to your privacy extends to all aspects of our services, including the use of AI and data sharing practices.
    </p>
  </div>
</div>







      </div>

    </div>
  );
};

export default PrivacyPage;
