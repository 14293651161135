import React, { useState } from 'react';

const TextRefinementAssistant = ({ setPopupMessageWithTimeout }) => {
  const [loading, setLoading] = useState(false);

  const getHighlightedText = () => {
    let selectedText = '';
    if (window.getSelection) {
      selectedText = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== 'Control') {
      selectedText = document.selection.createRange().text;
    }
    return selectedText;
  };

  const fetchTextRefinement = async () => {
    const text = getHighlightedText();
    if (!text.trim()) {
      setPopupMessageWithTimeout("Please highlight some text to refine.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/text-refine`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      replaceSelectedText(data.refinedText || "No refinements found.");
    } catch (error) {
      console.error("Error refining text:", error);
    } finally {
      setLoading(false);
    }
  };

  const replaceSelectedText = (replacementText) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(replacementText));
    }
  };

  return (
    <>
      <button className="btn btn-primary btn-sm get-in-touch" type="button" onClick={fetchTextRefinement} disabled={loading}>
        {loading ? 'Refining...' : 'Text Refine'}
      </button>
    </>
  );
};

export default TextRefinementAssistant;
