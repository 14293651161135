import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import 'animate.css';

const About = () => {
  const [scrollDirection, setScrollDirection] = useState(null);

  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const [ref5, inView5] = useInView({ triggerOnce: true });
  const [ref6, inView6] = useInView({ triggerOnce: true });

  useEffect(() => {
    let lastScrollTop = window.pageYOffset;

    const onScroll = () => {
      const currentScrollTop = window.pageYOffset;
      setScrollDirection(currentScrollTop > lastScrollTop ? 'down' : 'up');
      lastScrollTop = currentScrollTop;
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <div className="site-section" id="contact-section">
        <section className="site-section">
          <div className="container aboutContainer">
            <div className="row align-items-center justify-content-around">
              <div
                ref={ref1}
                className={`col-lg-4 ${inView1 ? 'animate__animated animate__fadeInLeftBig' : ''}`}
                data-jarallax-element="50"
              >
                <img src="/images/img_5.webp" alt="" className="img-fluid mb-4 mb-lg-0" />
              </div>
              <div
                className="col-lg-8"
                data-jarallax-element="-50"
                style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
              >
                <h2 className="text-primary">Revolutionize Your Communication</h2>
                <p className="lead">
                  U-Mail is transforming the way you communicate by integrating multiple messaging platforms into one seamless interface. Our AI combines conversations from text messages, Messenger, Instagram Chat, and more into a single, coherent dialogue. Embrace the future of communication with U-Mail.
                </p>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div
                ref={ref2}
                className={`col-lg-6 mt-3 mt-lg-0 `}
              >
                <div style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
                  <h2>Be You with U-Mail</h2>
                  <p className="lead">
                    Speak your true thoughts and let U-Mail make you sound professional. Experience the perfect blend of authenticity and sophistication with our AI-driven platform, ensuring every message is clear, polished, and impactful.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
              <div className="iframe-wrapper" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
  <video
    width="100%"
    height="300%"
    controls
    autoPlay
    loop
    style={{ borderRadius: '8px' }}
  >
    <source src="./Videos/be_you_1.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>

              </div>
            </div>
          </div>
        </section>

        <section className="section ft-feature-1">
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-12 w-100 ft-feature-1-content">
                <div className="row align-items-center">
                  <div
                    ref={ref3}
                    className={`col-lg-5 ${inView3 ? 'animate__animated animate__fadeInRightBig' : ''}`}
                    data-jarallax-element="50"
                  >
                    <img src="/images/img_91.png" alt="" className="img-fluid mb-4 mb-lg-0" />
                  </div>
                  <div
                    ref={ref4}
                    className={`col-lg-7 ml-auto`}
                    data-jarallax-element="-50"
                    style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
                  >
                    <div className="mb-5">
                      <h2 className="d-flex align-items-center"><span>Personalize Your Experience</span></h2>
                      <p className="lead">
                        U-Mail adapts to your unique communication style with customizable settings. From voice recognition to AI-powered editing, enjoy a tailored experience that enhances your efficiency without compromising your personal touch.
                      </p>
                    </div>
                    <div>
                      <h2 className="d-flex align-items-center"><span>Boost Your Productivity</span></h2>
                      <p className="lead">
                        Dictate messages on-the-go and let U-Mail's AI refine them instantly. Save time, increase productivity, and focus on what truly matters with the help of our advanced communication tools.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row align-items-center">
            <div
              ref={ref5}
              className={`col-md-6`}
              style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
            >
              <h2>Effortless Translations</h2>
              <p className="lead">
                Instantly translate any message into any language with U-Mail. Our AI ensures that the original context is preserved, allowing you to communicate effortlessly across the globe and break down language barriers.
              </p>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <div className="iframe-wrapper" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/bARw8gr3wqc?autoplay=1&loop=1&playlist=bARw8gr3wqc"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="autoplay; clipboard-write; accelerometer; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  style={{ borderRadius: '8px' }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <section className="section ft-feature-1">
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-12 w-100 ft-feature-1-content">
                <div className="row align-items-center">
                  <div
                    ref={ref6}
                    className={`col-lg-5 ${inView6 ? 'animate__animated animate__bounceInLeft' : ''}`}
                    data-jarallax-element="50"
                  >
                    <img src="/images/img_5.webp" alt="" className="img-fluid mb-4 mb-lg-0" />
                  </div>
                  <div
                    className={`col-lg-6 ml-auto `}
                    data-jarallax-element="-50"
                    style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
                  >
                    <h2 className="d-flex align-items-center"><span>Secure and Private</span></h2>
                    <p className="lead">
                      Your privacy is our top priority. U-Mail uses state-of-the-art security measures to protect your communications and personal data. Rest assured, we never share your information with third parties or AI machine learning tools.
                    </p>
                    <h2 className="d-flex align-items-center"><span>Stay Ahead with U-Mail</span></h2>
                    <p className="lead">
                      Stay connected and efficient no matter where you are. U-Mail's advanced speech-to-text and AI enhancements keep you in sync with the pace of modern business, making it the perfect tool for professionals who value smart, quick communication.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default About;





// import React from 'react';

// const About = () => {
//   return (
//     <>
//       <div className="site-section" id="contact-section">
//         <section className="site-section">
//           <div className="container">
//             <div className="row align-items-center justify-content-around">
//               <div className="col-lg-4" data-jarallax-element="50">
//                 <img src="/images/img_51.png" alt="" className="img-fluid mb-4 mb-lg-0" />
//               </div>
//               <div className="col-lg-8" data-jarallax-element="-50" style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px',  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"  }}>
//                 <h2 className="text-primary">Revolutionize Your Communication</h2>
//                 <p className="lead">
//                   U-Mail is transforming the way you communicate by integrating multiple messaging platforms into one seamless interface. Our AI combines conversations from text messages, Messenger, Instagram Chat, and more into a single, coherent dialogue. Embrace the future of communication with U-Mail.
//                 </p>
//               </div>
//             </div>
//             <div className="row align-items-center mt-5">
//               <div className="col-lg-6 mt-3 mt-lg-0">
//                 <div style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"  }}>
//                   <h2>Be You with U-Mail</h2>
//                   <p className="lead">
//                     Speak your true thoughts and let U-Mail make you sound professional. Experience the perfect blend of authenticity and sophistication with our AI-driven platform, ensuring every message is clear, polished, and impactful.
//                   </p>
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="iframe-wrapper" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
//                   <iframe
//                     width="100%"
//                     height="500"
//                     src="https://www.youtube.com/embed/MuOwjBqr6QI?autoplay=1&loop=1&playlist=MuOwjBqr6QI"
//                     title="YouTube video player"
//                     frameBorder="0"
//                     allow="autoplay; clipboard-write; accelerometer; encrypted-media; gyroscope; picture-in-picture; web-share"
//                     referrerPolicy="strict-origin-when-cross-origin"
//                     allowFullScreen
//                     style={{ borderRadius: '8px' }}
//                   ></iframe>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//         <section className="section ft-feature-1">
//           <div className="container">
//             <div className="row align-items-stretch">
//               <div className="col-12 w-100 ft-feature-1-content">
//                 <div className="row align-items-center">
//                   <div className="col-lg-5" data-jarallax-element="50">
//                     <img src="/images/img_91.png" alt="" className="img-fluid mb-4 mb-lg-0" />
//                   </div>
//                   <div className="col-lg-7 ml-auto" data-jarallax-element="-50" style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"  }}>
//                     <div className="mb-5">
//                       <h2 className="d-flex align-items-center"><span>Personalize Your Experience</span></h2>
//                       <p className="lead">
//                         U-Mail adapts to your unique communication style with customizable settings. From voice recognition to AI-powered editing, enjoy a tailored experience that enhances your efficiency without compromising your personal touch.
//                       </p>
//                     </div>
//                     <div>
//                       <h2 className="d-flex align-items-center"><span>Boost Your Productivity</span></h2>
//                       <p className="lead">
//                         Dictate messages on-the-go and let U-Mail's AI refine them instantly. Save time, increase productivity, and focus on what truly matters with the help of our advanced communication tools.
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//         <div className="container">
//           <div className="row align-items-center">
//             <div className="col-md-6" style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"  }}>
//               <h2>Effortless Translations</h2>
//               <p className="lead">
//                 Instantly translate any message into any language with U-Mail. Our AI ensures that the original context is preserved, allowing you to communicate effortlessly across the globe and break down language barriers.
//               </p>
//             </div>
//             <div className="col-md-6 mt-3 mt-md-0">
//               <div className="iframe-wrapper" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
//                 <iframe
//                   width="100%"
//                   height="500"
//                   src="https://www.youtube.com/embed/bARw8gr3wqc?autoplay=1&loop=1&playlist=bARw8gr3wqc"
//                   title="YouTube video player"
//                   frameBorder="0"
//                   allow="autoplay; clipboard-write; accelerometer; encrypted-media; gyroscope; picture-in-picture; web-share"
//                   referrerPolicy="strict-origin-when-cross-origin"
//                   allowFullScreen
//                   style={{ borderRadius: '8px' }}
//                 ></iframe>
//               </div>
//             </div>
//           </div>
//         </div>

//         <section className="section ft-feature-1">
//           <div className="container">
//             <div className="row align-items-stretch">
//               <div className="col-12 w-100 ft-feature-1-content">
//                 <div className="row align-items-center">
//                   <div className="col-lg-5" data-jarallax-element="50">
//                     <img src="/images/img_5.webp" alt="" className="img-fluid mb-4 mb-lg-0" />
//                   </div>
//                   <div className="col-lg-6 ml-auto" data-jarallax-element="-50" style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"  }}>
//                     <h2 className="d-flex align-items-center"><span>Secure and Private</span></h2>
//                     <p className="lead">
//                       Your privacy is our top priority. U-Mail uses state-of-the-art security measures to protect your communications and personal data. Rest assured, we never share your information with third parties or AI machine learning tools.
//                     </p>
//                     <h2 className="d-flex align-items-center"><span>Stay Ahead with U-Mail</span></h2>
//                     <p className="lead">
//                       Stay connected and efficient no matter where you are. U-Mail's advanced speech-to-text and AI enhancements keep you in sync with the pace of modern business, making it the perfect tool for professionals who value smart, quick communication.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </>
//   );
// };

// export default About;
