import React, { useState } from 'react';

const GoogleCalendarScheduler = ({ setPopupMessageWithTimeout, mailContent, setMailContent, userEmail }) => {
  const [loading, setLoading] = useState(false);

  const addGoogleCalendarEvent = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/add-google-calendar-event`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: userEmail,
          to: mailContent.to,
          subject: mailContent.subject,
          message: mailContent.message,
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMailContent(prev => ({
        ...prev,
        message: `${prev.message}\n\nGoogle Calendar Event: ${data.htmlLink}`
      }));
      setPopupMessageWithTimeout("Google Calendar event added successfully!");
    } catch (error) {
      console.error("Error adding Google Calendar event:", error);
      setPopupMessageWithTimeout(`Failed to add Google Calendar event: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      className="btn btn-primary btn-sm get-in-touch"
      type="button"
      onClick={addGoogleCalendarEvent}
      disabled={loading}
    >
      {loading ? 'Adding Event...' : 'Google Calendar Event'}
    </button>
  );
};

export default GoogleCalendarScheduler;
