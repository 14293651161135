import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faXTwitter,
  faInstagram,
  faLinkedinIn,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="footer-heading mb-4">Links</h2>
              <ul className="list-unstyled">
                <li>
                  <a href="/join" className="py-2 d-block" onClick={scrollToTop}>
                    Join
                  </a>
                </li>
                <li>
                  <a href="/faq" className="py-2 d-block" onClick={scrollToTop}>
                    FAQs
                  </a>
                </li>
                <li>
                  <a href="/pricing" className="py-2 d-block" onClick={scrollToTop}>
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="/privacy" className="py-2 d-block" onClick={scrollToTop}>
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/TermsAndServices" className="py-2 d-block" onClick={scrollToTop}>
                    Terms And Services
                  </a>
                </li>
              </ul>
              
            </div>

            <div className="col-md-3"></div>
            <div className="col-md-3">
              <h2 className="footer-heading mb-4">Follow Us</h2>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=61561342615040"
                  className="pl-0 pr-3 social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  href="https://x.com/umailai"
                  className="pl-3 pr-3 social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faXTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/umail.ai"
                  className="pl-3 pr-3 social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://www.linkedin.com/company/umail/"
                  className="pl-3 pr-3 social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a
                  href="https://www.tiktok.com/@umail.ai"
                  className="pl-3 pr-3 social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              </div>
             
            </div>
            <div className="col-md-4">
            <h2 className="footer-heading mb-4 apps">Get the apps</h2>
               <div className="app-store-icon mt-4">

              

                <a target="_blank" rel="noopener noreferrer">
                   
                  <img src="/images/apple.png" alt="Download on the App Store" className="app-store-icon apple" />
             
                </a>

                <a target="_blank" rel="noopener noreferrer">
                   
                   <img src="/images/Google.png" alt="Download on the App Store" className="app-store-icon play" />
                 
                    </a>
                  
                
                <a target="_blank" rel="noopener noreferrer">
                   
                <img src="/images/chrome1.png" alt="Download on the App Store" className="app-store-icon chrome" />
              
                 </a>

              </div>
              </div>
            <div className="col-md-12">
              <div className="border-top pt-5 text-center">
                <p>
                  Copyright &copy; {currentYear}. All Rights Reserved. —
                  Designed with love by U-Mail LLC
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
