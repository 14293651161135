import React, { useState, useEffect } from 'react';
import { auth, storage } from '../../firebase-config';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import html2pdf from 'html2pdf.js';
import EditOptions from './NormalEditOptions'; // Import EditOptions

const JobAssistant = ({ setPopupMessageWithTimeout, setShowJobAssistantModal }) => {
  const [jobDescription, setJobDescription] = useState('');
  const [resumeUrl, setResumeUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingStage, setLoadingStage] = useState(-1);
  const [resumeExists, setResumeExists] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState('Coral');
  const [resumeFile, setResumeFile] = useState(null);
  const [coverLetter, setCoverLetter] = useState('');
  const [tailoredResume, setTailoredResume] = useState('');
  const [userNotes, setUserNotes] = useState('');
  const [resumeGenerated, setResumeGenerated] = useState(false);
  const [useSignature, setUseSignature] = useState(false);
  const [showCoverLetter, setShowCoverLetter] = useState(true); // Track if cover letter or resume is shown

  const templates = ['Coral', 'Serif', 'Swiss', 'Modern Writer', 'Spearmint'];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        checkResumeExistence(currentUser.uid);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkResumeExistence = async (userId) => {
    try {
      const storageRef = ref(storage, `resumes/${userId}/resume.pdf`);
      const url = await getDownloadURL(storageRef);
      setResumeUrl(url);
      setResumeExists(true);
    } catch (error) {
      setResumeExists(false);
      setPopupMessageWithTimeout('Resume not found. Please upload your resume.');
    }
  };

  const handleResumeUpload = async () => {
    if (!resumeFile) {
      setPopupMessageWithTimeout("Please select a resume file first.");
      return;
    }

    if (!user) {
      setPopupMessageWithTimeout("You must be logged in to upload a resume.");
      return;
    }

    const renamedFile = new File([resumeFile], 'resume.pdf', { type: resumeFile.type });
    const storageRef = ref(storage, `resumes/${user.uid}/resume.pdf`);
    setLoading(true);

    try {
      await uploadBytes(storageRef, renamedFile);
      const url = await getDownloadURL(storageRef);
      setResumeUrl(url);
      setResumeExists(true);
      setPopupMessageWithTimeout("Resume uploaded successfully.");
    } catch (error) {
      setPopupMessageWithTimeout("Failed to upload resume. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateDocuments = async () => {
    if (!resumeExists) {
      setPopupMessageWithTimeout("Your resume could not be found. Please upload it.");
      return;
    }

    if (!jobDescription.trim()) {
      setPopupMessageWithTimeout("Please paste the job description.");
      return;
    }

    setLoading(true);
    setLoadingStage(-1);
    setTimeout(() => {
        console.log('Setting stage to 0');
        setLoadingStage(0); // Cross-referencing resume and job description
      }, 2000);
    
      await generateCoverLetter(resumeUrl, jobDescription, userNotes);
    
      setTimeout(() => {
        console.log('Setting stage to 1');
        setLoadingStage(1); // Reviewing company's key features
      }, 4000);
    
      await generateTailoredResume(resumeUrl, jobDescription);
    
      setTimeout(() => {
        console.log('Setting stage to 2');
        setLoadingStage(2); // Finalizing the documents
      }, 6000);
  };

  const generateCoverLetter = async (resumeUrl, jobDescription, userNotes) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-cover-letter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resumeUrl, jobDescription, userNotes }),
      });

      const data = await response.json();
      const formattedCoverLetter = data.coverLetter
        .split('\n')
        .map((paragraph) => `<p>${paragraph.trim()}</p>`)
        .join('');

      setCoverLetter(formattedCoverLetter);
    } catch (error) {
      setPopupMessageWithTimeout("Error generating cover letter.");
    }
  };

  const generateTailoredResume = async (resumeUrl, jobDescription) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-tailored-resume`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resumeUrl, jobDescription, template: selectedTemplate }),
      });

      const data = await response.json();
      setTailoredResume(data.tailoredResume);
      setResumeGenerated(true);
      setLoading(false);
    } catch (error) {
      setPopupMessageWithTimeout("Error generating tailored resume.");
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setResumeFile(file);
    } else {
      setPopupMessageWithTimeout("Please upload a PDF file.");
    }
  };

  // Function to download cover letter as PDF
  const downloadCoverLetterPDF = () => {
    const coverLetterElement = document.querySelector('.cover-letter-container');
    const opt = {
      margin: 1,
      filename: 'cover_letter.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().set(opt).from(coverLetterElement).save();
  };

  // Function to download resume as PDF
  const downloadResumePDF = () => {
    const resumeElement = document.querySelector('.resume-container');
    const opt = {
      margin: 1,
      filename: 'resume.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().set(opt).from(resumeElement).save();
  };

  // Function to view cover letter as PDF in a new tab
  const viewCoverLetterPDF = () => {
    const coverLetterElement = document.querySelector('.cover-letter-container');
    const opt = {
      margin: 1,
      filename: 'cover_letter.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().set(opt).from(coverLetterElement).outputPdf('dataurlnewwindow');
  };

  // Function to view resume as PDF in a new tab
  const viewResumePDF = () => {
    const resumeElement = document.querySelector('.resume-container');
    const opt = {
      margin: 1,
      filename: 'resume.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().set(opt).from(resumeElement).outputPdf('dataurlnewwindow');
  };

  // Handling signature toggle
  const handleSignatureToggle = () => {
    setUseSignature(!useSignature);
  };

  return (
    <div>
      {loading && (
        <div className="Tloading-overlay">
          <div className="spinner-container">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            {loadingStage >= 0 && (
                <p className="loading-text">
                    {loadingStage === 0
                    ? 'We’re analyzing your resume and matching it with the job description...'
                    : loadingStage === 1
                    ? 'We’re reviewing the company’s key features to highlight areas of mutual interest...'
                    : loadingStage === 2
                    ? 'Finalizing your tailored documents... Almost there!'
                    : 'Starting the process...'}
                </p>
                )}

          </div>
        </div>
      )}

      <div className={`modal fade show`} tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg job" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Job Assistant</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowJobAssistantModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              {resumeExists ? (
                <>
                  <div className="form-group mt-4">
                    <label htmlFor="jobDescription">Paste Job Description:</label>
                    <textarea
                      className="form-control"
                      id="jobDescription"
                      rows="6"
                      value={jobDescription}
                      onChange={(e) => setJobDescription(e.target.value)}
                      placeholder="Paste the job description here..."
                    ></textarea>
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="userNotes">Add Your Notes (Optional):</label>
                    <textarea
                      className="form-control"
                      id="userNotes"
                      rows="4"
                      value={userNotes}
                      onChange={(e) => setUserNotes(e.target.value)}
                      placeholder="Add any specific points you want highlighted in your cover letter..."
                    ></textarea>
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="templateSelect">Select Resume Template:</label>
                    <select
                      className="form-control"
                      id="templateSelect"
                      value={selectedTemplate}
                      onChange={(e) => setSelectedTemplate(e.target.value)}
                    >
                      {templates.map((template) => (
                        <option key={template} value={template}>
                          {template}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    className="btn btn-dark mt-4"
                    onClick={handleGenerateDocuments}
                    disabled={loading}
                  >
                    Generate Cover Letter & Resume
                  </button>
                </>
              ) : (
                <>
                  <p>No resume found. Please upload your resume to continue.</p>
                  <input
                    type="file"
                    className="form-control"
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleResumeUpload}
                    disabled={loading}
                  >
                    Upload Resume
                  </button>
                </>
              )}

              {/* Edit Options Moved Below the Button */}
              {resumeGenerated && (
                <>
                  <div className="edit-options-container mb-4">
                    <EditOptions
                      handleSignatureToggle={handleSignatureToggle}
                      useSignature={useSignature}
                      setMailContent={setCoverLetter}
                    />
                  </div>

                  {/* Toggle between cover letter and resume */}
                  <div className="toggle-container mt-4">
                    <button
                      className={`btn ${showCoverLetter ? 'btn-dark' : 'btn-light'}`}
                      onClick={() => setShowCoverLetter(true)}
                    >
                      View Cover Letter
                    </button>
                    <button
                      className={`btn ${!showCoverLetter ? 'btn-dark' : 'btn-light'}`}
                      onClick={() => setShowCoverLetter(false)}
                    >
                      View Resume
                    </button>
                  </div>

                  {/* Conditional rendering of either cover letter or resume */}
                  {showCoverLetter ? (
                    <div className="cover-letter-container p-3">
                      <h3>Cover Letter</h3>
                      <div
                        className="border p-3"
                        contentEditable="true"
                        dangerouslySetInnerHTML={{ __html: coverLetter }}
                      ></div>
                      <div className="modal-footer">
                        <button className="btn btn-dark" onClick={downloadCoverLetterPDF}>
                          Download Cover Letter as PDF
                        </button>
                        <button className="btn btn-dark" onClick={viewCoverLetterPDF}>
                          View Cover Letter as PDF
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="resume-container p-3">
                      <h3>Resume</h3>
                      <div
                        className={`border p-3 ${selectedTemplate.toLowerCase()}-resume-container`}
                        contentEditable="true"
                        dangerouslySetInnerHTML={{ __html: tailoredResume }}
                      ></div>
                      <div className="modal-footer">
                        <button className="btn btn-dark" onClick={downloadResumePDF}>
                          Download Resume as PDF
                        </button>
                        <button className="btn btn-dark" onClick={viewResumePDF}>
                          View Resume as PDF
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobAssistant;
