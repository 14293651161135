import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase-config';
import { createUserWithEmailAndPassword, updateProfile, signOut, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, getDocs, collection, query, where } from 'firebase/firestore';
import ReactCountryFlag from "react-country-flag";

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [ageRange, setAgeRange] = useState('');
  const [accountCount, setAccountCount] = useState('');
  const [country, setCountry] = useState('');
  const [cityState, setCityState] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [features, setFeatures] = useState([]);
  const navigate = useNavigate();
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [otherFeature, setOtherFeature] = useState('');
  const [otherLanguage, setOtherLanguage] = useState('');
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const languages = [
    { name: 'English', code: 'en', flagCode: 'GB', key: 'en-GB' },
    { name: 'Spanish', code: 'es', flagCode: 'ES', key: 'es-ES' },
    { name: 'French', code: 'fr', flagCode: 'FR', key: 'fr-FR' },
    { name: 'Italian', code: 'it', flagCode: 'IT', key: 'it-IT' },
    { name: 'Latin Spanish', code: 'es', flagCode: 'MX', key: 'es-MX' },
    { name: 'Greek', code: 'el', flagCode: 'GR', key: 'el-GR' },
    { name: 'German', code: 'de', flagCode: 'DE', key: 'de-DE' },
    { name: 'Punjabi', code: 'pa', flagCode: 'IN', key: 'pa-IN' },
    { name: 'Mandarin Chinese', code: 'zh', flagCode: 'CN', key: 'zh-CN' },
    { name: 'Cantonese Chinese', code: 'zh', flagCode: 'HK', key: 'zh-HK' },
    { name: 'Japanese', code: 'ja', flagCode: 'JP', key: 'ja-JP' },
    { name: 'Hindi', code: 'hi', flagCode: 'IN', key: 'hi-IN' },
    { name: 'Russian', code: 'ru', flagCode: 'RU', key: 'ru-RU' },
    { name: 'Portuguese', code: 'pt', flagCode: 'PT', key: 'pt-PT' },
    { name: 'Arabic', code: 'ar', flagCode: 'SA', key: 'ar-SA' },
    { name: 'Hebrew', code: 'he', flagCode: 'IL', key: 'he-IL' },
    { name: 'Other', code: '', flagCode: '', key: 'other' },
  ];

  const industryOptions = [
    'Medical',
    'Legal',
    'Finance',
    'Technology',
    'Education',
    'Real Estate',
    'Other'
  ];

  const LanguageCheckboxes = ({ selectedLanguages, setSelectedLanguages }) => {
    const handleLanguageChange = (e) => {
      const value = e.target.value;
      if (e.target.checked) {
        setSelectedLanguages((prevLanguages) => [...prevLanguages, value]);
      } else {
        setSelectedLanguages((prevLanguages) => prevLanguages.filter((lang) => lang !== value));
      }
    };

    return (
      <div className="form-group">
        <label>Available Languages</label>
        <div className="d-flex flex-wrap">
          {languages.map((language, index) => (
            <div key={index} className="form-check form-check-inline">
              <input
                type="checkbox"
                className="form-check-input"
                value={language.key}
                onChange={handleLanguageChange}
                checked={selectedLanguages.includes(language.key)}
              />
              <label className="form-check-label">
                {language.flagCode && (
                  <ReactCountryFlag 
                    countryCode={language.flagCode} 
                    svg 
                    style={{ marginRight: '10px' }}
                  />
                )}
                {language.name}
              </label>
              {language.key === 'other' && selectedLanguages.includes('other') && (
                <input
                  type="text"
                  className="form-control mt-2"
                  value={otherLanguage}
                  onChange={(e) => setOtherLanguage(e.target.value)}
                  placeholder="Please specify"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const IndustryCheckboxes = ({ selectedIndustries, setSelectedIndustries }) => {
    const handleIndustryChange = (e) => {
      const value = e.target.value;
      if (e.target.checked) {
        setSelectedIndustries((prevIndustries) => [...prevIndustries, value]);
      } else {
        setSelectedIndustries((prevIndustries) => prevIndustries.filter((ind) => ind !== value));
      }
    };

    return (
      <div className="form-group">
        <label>Industries</label>
        <div className="d-flex flex-wrap">
          {industryOptions.map((industry, index) => (
            <div key={index} className="form-check form-check-inline">
              <input
                type="checkbox"
                className="form-check-input"
                value={industry}
                onChange={handleIndustryChange}
                checked={selectedIndustries.includes(industry)}
              />
              <label className="form-check-label">
                {industry}
              </label>
              {industry === 'Other' && selectedIndustries.includes('Other') && (
                <input
                  type="text"
                  className="form-control mt-2"
                  value={otherFeature}
                  onChange={(e) => setOtherFeature(e.target.value)}
                  placeholder="Please specify"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const allCountries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "North Korea",
    "South Korea",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ];

  const topCountries = ["United States", "Australia", "Djibouti", "North Korea"];

  const checkUniqueField = async (field, value) => {
    const q = query(collection(db, 'users'), where(field, '==', value));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPopupMessage('Passwords do not match');
      setTimeout(() => setPopupMessage(''), 4000);
      return;
    }

    // Check if username or phone number is already taken
    const isUsernameUnique = await checkUniqueField('username', username);
    const isPhoneNumberUnique = phoneNumber ? await checkUniqueField('phoneNumber', phoneNumber) : true;

    if (!isUsernameUnique) {
      setPopupMessage('Username already taken. Please try again with a different one.');
      setTimeout(() => setPopupMessage(''), 4000);
      return;
    }

    if (!isPhoneNumberUnique) {
      setPopupMessage('Phone number already in use. Please try again with a different one.');
      setTimeout(() => setPopupMessage(''), 4000);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Optionally update user profile with username
      if (username) {
        await updateProfile(userCredential.user, { displayName: username });
      }
      // Send email verification
      await sendEmailVerification(userCredential.user);

      // Save additional user info to Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        username,
        email,
        phoneNumber,
        ageRange,
        accountCount,
        country,
        cityState,
        features,
        selectedLanguages,
        otherFeature,
        otherLanguage,
        selectedIndustries,
      });

      // Log out immediately after registration
      await signOut(auth);
      setPopupMessage('Registration successful! A verification email has been sent to your email address.');
      setTimeout(() => setPopupMessage(''), 4000);
      // Clear form fields
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setUsername('');
      setPhoneNumber('');
      setAgeRange('');
      setAccountCount('');
      setCountry('');
      setCityState('');
      setFeatures([]);
      setSelectedLanguages([]);
      setOtherFeature('');
      setOtherLanguage('');
      setSelectedIndustries([]);
    } catch (error) {
      console.error("Error signing up: ", error.message);
      if (error.code === 'auth/email-already-in-use') {
        setPopupMessage('You cannot use the same email more than once.');
      } else {
        setPopupMessage('An unexpected error occurred. Please try again.');
      }
      setTimeout(() => setPopupMessage(''), 4000);
    }
  };

  const closePopup = () => setPopupMessage('');

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCountries = searchTerm
    ? allCountries.filter((country) =>
        country.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : allCountries;

  return (
    <div className="register-container">
      <div className="register-card">
        <h2 className="site-section-heading">Join the waitlist</h2>
        <p><strong>Want to become a Beta Tester? Join the U-Mail exclusive free beta program launching this summer! Be among the select few to revolutionize their email experience. Spots are limited, so don't miss out on this opportunity. Sign up now and be a part of the future of email communication!</strong></p>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Phone Number (Optional)"
            />
          </div>
          <div className="form-group">
            <select className="form-control" value={ageRange} onChange={(e) => setAgeRange(e.target.value)} required>
              <option value="">Age Range</option>
              <option value="18-25">18-25</option>
              <option value="26-35">26-35</option>
              <option value="36-45">36-45</option>
              <option value="46-55">46-55</option>
              <option value="56+">56+</option>
            </select>
          </div>
          <div className="form-group">
            <select className="form-control" value={accountCount} onChange={(e) => setAccountCount(e.target.value)} required>
              <option value="" disabled>Please select account type</option>
              <option value="free">Free Account only</option>
              <option value="1">1 Account</option>
              <option value="2">2 Accounts</option>
              <option value="3">3 Accounts</option>
              <option value="4+">More than 3</option>
            </select>
          </div>
          <div className="form-group">
            <select className="form-control" value={country} onChange={(e) => setCountry(e.target.value)} required>
              <option value="">Select Country</option>
              {topCountries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
              {filteredCountries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
            

          <div className="form-group">
  <label className="form-label">What features are you looking forward to the most? (Optional)</label>
  <div className="d-flex flex-wrap gap-2 mt-2">
    {['Professional Messages', 'Moods & Tones', 'Text Integration', 'Social Media Integration', 'Automatic Replies', 'Ghosting Features', 'Synonym Assistant', 'Industry-Specific Lingo', 'All-in-One Communications', 'Voice to text functionality', 'AI-assisted email creation', 'Language Translations', 'Other'].map((feature, index) => (
      <div key={index} className="form-check form-check-inline me-3 mb-2">
        <input
          type="checkbox"
          className="form-check-input"
          name="features"
          value={feature}
          onChange={(e) => {
            if (e.target.checked) {
              setFeatures((prevFeatures) => [...prevFeatures, feature]);
            } else {
              setFeatures((prevFeatures) => prevFeatures.filter((f) => f !== feature));
            }
          }}
          checked={features.includes(feature)}
        />
        <label className="form-check-label ms-1">
          {feature}
        </label>
        {feature === 'Language Translations' && features.includes('Language Translations') && (
          <LanguageCheckboxes selectedLanguages={selectedLanguages} setSelectedLanguages={setSelectedLanguages} />
        )}
        {feature === 'Industry-Specific Lingo' && features.includes('Industry-Specific Lingo') && (
          <IndustryCheckboxes selectedIndustries={selectedIndustries} setSelectedIndustries={setSelectedIndustries} />
        )}
        {feature === 'Other' && features.includes('Other') && (
          <input
            type="text"
            className="form-control mt-2"
            value={otherFeature}
            onChange={(e) => setOtherFeature(e.target.value)}
            placeholder="Please specify"
          />
        )}
      </div>
    ))}
  </div>
</div>



          <div className="text-center">
            <button type="submit" className="btn-primary">Join U-Mail</button>
          </div>
        </form>
        {popupMessage && <div className="popup-message">{popupMessage} <button onClick={closePopup}>Close</button></div>}
      </div>
    </div>
  );
};

export default Register;
