import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';  // Import getFirestore
import { getStorage } from "firebase/storage"; 

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "u-mail-ca0d2.firebaseapp.com",
  databaseURL: "https://u-mail-ca0d2-default-rtdb.firebaseio.com",
  projectId: "u-mail-ca0d2",
  storageBucket: "u-mail-ca0d2.appspot.com",
  messagingSenderId: "897123398760",
  appId: "1:897123398760:web:6a04b99a8919ae74ae37dd",
  measurementId: "G-200K52259X"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Obtain the auth object and configure Google Auth provider
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Initialize Firestore and export it
const db = getFirestore(app);

const storage = getStorage(app); // Add this line

export { app as firebase, auth, provider, db, storage };
