import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SentMails = ({ accessToken, handleMailClick, showMailPopup, selectedMail, handleClosePopup }) => {
  const [sentEmails, setSentEmails] = useState([]);

  useEffect(() => {
    if (accessToken) {
      fetchSentEmails();
    }
  }, [accessToken]);

  // Fetch sent emails from Gmail API
  const fetchSentEmails = async () => {
    try {
      const response = await axios.get('https://www.googleapis.com/gmail/v1/users/me/messages', {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: {
          q: 'in:sent', // Query for sent emails
          maxResults: 20 // Limit the number of results (change as needed)
        }
      });

      const messages = response.data.messages || [];
      if (messages.length > 0) {
        const detailedMessages = await Promise.all(
          messages.map(async (message) => {
            const messageDetails = await axios.get(
              `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
              { headers: { Authorization: `Bearer ${accessToken}` } }
            );
            return messageDetails.data;
          })
        );

        setSentEmails(detailedMessages);
      }
    } catch (error) {
      console.error('Error fetching sent emails:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h1>Sent U-Mails</h1>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Receiver</th>
            <th scope="col">Subject</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {sentEmails.map((mail, index) => {
            const toHeader = mail.payload.headers.find(header => header.name === 'To')?.value || 'Unknown';
            const subjectHeader = mail.payload.headers.find(header => header.name === 'Subject')?.value || 'No Subject';
            const dateHeader = mail.payload.headers.find(header => header.name === 'Date')?.value || 'Unknown Date';

            return (
              <tr key={mail.id || index} onClick={() => handleMailClick(mail)}>
                <td>{toHeader}</td>
                <td>{subjectHeader}</td>
                <td>{new Date(dateHeader).toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {showMailPopup && selectedMail && (
        <div className="popup">
          <div className="popup-inner">
            <button className="btn btn-dark" onClick={handleClosePopup}>Close</button>
            <h3>{selectedMail.payload.headers.find(header => header.name === 'Subject')?.value}</h3>
            <p>{selectedMail.snippet}</p>
            <p>Receiver: {selectedMail.payload.headers.find(header => header.name === 'To')?.value}</p>
            <p>Date: {new Date(selectedMail.payload.headers.find(header => header.name === 'Date')?.value).toLocaleString()}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SentMails;
