import React, { useEffect, useRef } from "react";
import Typed from "typed.js";

const Home = () => {
  const typedRef = useRef(null);

  useEffect(() => {
    const typed = new Typed(typedRef.current, {
      strings: ["Welcome to the future", "Welcome to U-MAIL"],
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 4000,
      startDelay: 1000,
      loop: true,
      showCursor: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <>
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle"></span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>

      <div>
        <section>
          <div className="site-blocks-cover">
            <div className="container">
              <div>
                <div className="frame">
                  <div className="plane-container">
                    <a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1131.53px"
                        height="379.304px"
                        viewBox="0 0 1131.53 379.304"
                        className="plane"
                      >
                        <polygon
                          fill="#D8D8D8"
                          points="72.008,0 274.113,140.173 274.113,301.804 390.796,221.102 601.682,367.302 1131.53,0.223"
                        />
                        <polygon
                          fill="#C4C4C3"
                          points="1131.53,0.223 274.113,140.173 274.113,301.804 390.796,221.102"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                <div className="clouds1">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <svg
                      key={index}
                      xmlns="http://www.w3.org/2000/svg"
                      width="762px"
                      height="331px"
                      viewBox="0 0 762 331"
                      className={`cloud ${
                        index % 2 === 0
                          ? "big front slowest"
                          : "distant super-slow massive"
                      }`}
                    >
                      <path
                        fill="#FFFFFF"
                        d={`M715.394,228h-16.595c0.79-5.219,1.201-10.562,1.201-16c0-58.542-47.458-106-106-106
                          c-8.198,0-16.178,0.932-23.841,2.693C548.279,45.434,488.199,0,417.5,0c-84.827,0-154.374,65.401-160.98,148.529
                          C245.15,143.684,232.639,141,219.5,141c-49.667,0-90.381,38.315-94.204,87H46.607C20.866,228,0,251.058,0,279.5
                          S20.866,331,46.607,331h668.787C741.133,331,762,307.942,762,279.5S741.133,228,715.394,228z`}
                      />
                    </svg>
                  ))}
                </div>
                
              </div>

              <div className="row align-items-center justify-content-center text-center">
                <div
                  className="col-md-12"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="row justify-content-center mb-4">
                    <div className="col-md-10 text-center">
                      <h1>
                        <span ref={typedRef} className="typed-words"></span>
                      </h1>
                      <br />
                      <p className="lead mb-5">
                        Experience the future of communication with U-Mail—
                        connecting U to the world!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="site-section" id="about-section">
        <div className="container">
          <div className="row mb-5">
            <div
              className="col-md-6 ml-auto mb-5 order-md-2 justify-content-center"
              data-jarallax-element="50"
            >
              <img
                src="/images/img_5.webp"
                alt=""
                className="img-fluid rounded hmoeImg"
              />
            </div>
            <div className="col-md-6 order-md-1" data-jarallax-element="-50">
              <div className="row">
                <div className="col-12">
                  <div className="text-left pb-1">
                    <h2 className="site-section-heading">U-Mail</h2>
                  </div>
                </div>
                
                <div className="clouds2">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <svg
                      key={index}
                      xmlns="http://www.w3.org/2000/svg"
                      width="962px"
                      height="631px"
                      viewBox="0 0 762 331"
                      className={`cloud ${
                        index % 2 === 0
                          ? "big front slowest"
                          : "distant super-slow massive"
                      }`}
                    >
                      <path
                        fill="#FFFFFF"
                        d={`M715.394,228h-16.595c0.79-5.219,1.201-10.562,1.201-16c0-58.542-47.458-106-106-106
                          c-8.198,0-16.178,0.932-23.841,2.693C548.279,45.434,488.199,0,417.5,0c-84.827,0-154.374,65.401-160.98,148.529
                          C245.15,143.684,232.639,141,219.5,141c-49.667,0-90.381,38.315-94.204,87H46.607C20.866,228,0,251.058,0,279.5
                          S20.866,331,46.607,331h668.787C741.133,331,762,307.942,762,279.5S741.133,228,715.394,228z`}
                      />
                    </svg>
                  ))}
                </div>
                <div className="col-12 mb-4">
                  <div className="col-lg-12" data-jarallax-element="-50" style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '8px', marginTop: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"  }}>
                    <p className="lead">
                      Welcome to U-Mail, where you revolutionize your communication. Our platform harnesses cutting-edge speech-to-text technology and AI-enhanced editing to deliver clear, professional messages effortlessly. Break down language barriers with instant translations and experience seamless global communication like never before.
                    </p>
                    <p className="lead">
                      Unleash your true voice with U-Mail's customized moods and tones, transforming your personal expressions into smooth, positive interactions. Whether you're engaging with international clients or coordinating with vendors, U-Mail's intelligent features elevate every conversation to new heights.
                    </p>
                    <p className="lead">
                      Designed for maximum efficiency, U-Mail empowers you to communicate with ease and precision. From busy professionals to everyday individuals, our innovative tools streamline your communications on the go, saving you time and boosting productivity.
                    </p>
                    <p className="lead">
                      Ready to transform your communication game? Visit our <a href="/faq">FAQ</a> page and discover how U-Mail can amplify your impact and connect you to the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
